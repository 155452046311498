/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  MOVIES: '/movies',
  MOVIE_DETAIL: '/movies/:id',
  ADD_MOVIES: '/movies/add',
  EDIT_MOVIES: '/movies/edit/:id',
  TABLE: '/tables',
  SAMPLE: '/sample',
  PROFILE: '/profile',
  PAYMENTS: '/payments',
  REQUESTS: '/requests',
  ORDERS: '/orders',
  DELIVERY: '/delivery',
  RETURN: '/return',
  NEW: '/new',
  ALL: '/all',
  REPORTS: '/reports',
  BULK_UPDATE: '/bulk-update',
  HOLDING_PERIOD: '/holding-period',
  UNFULFILLED_ORDERS: '/unfulfilled-orders',
  MASTER_LISTS: '/master-lists',
  APP_CONTENT: '/app-content',
  STORY: '/story',
  TOY: '/toy',
  PINCODE: '/pincode',
  SYSTEM_USERS: '/system-users',
  ADMINS: '/admins',
  END_USERS: '/end-users',
  DELIVERY_PARTNERS: '/delivery-partners',
  SUBSCRIPTION_PLANS: '/subscription-plans',
  TRIAL: '/trial',
  GARDEN: '/garden',
  ESTATE: '/estate',
  FOREST: '/forest',
  END_USER: '/end-user',
  LIBRARY: '/library',
  DAMAGES: '/damages',
  CLOSURE_REQUESTS: '/closure-requests',
  BAGS: '/packaging-materials',
  NEW_TOYS: '/new-toys',
  CHANGE_ADDRESS: '/change-address',
  BASIC_DETAILS: '/basic-details',
  INVENTORY_DETAILS: '/inventory-details',
  SUBSCRIPTION_PLAN_DETAILS: '/subscription-plan-details',
  ORDER_DETAILS: '/order-details',
  TRANSACTION_DETAILS: '/transaction-details',
  MONTHLY: '/monthly',
  QUARTERLY: '/3-months',
  ANNUALLY: '/12-months',
  EXPORTS: '/exports',
  SUGGESTED_TOY: '/suggested-toy',
  REPORT_DAMAGE: '/report-damage',
  RAISE_REQUEST: '/raise-request',
  SUPPORT: '/support',
  INVENTORY: '/inventory',
  INFLOW: '/inflow',
  OUTFLOW: '/outflow',
  NOTIFICATIONS: '/notifications',
  BULK_UPDATE_LOG: '/bulk-update-log',
  OFFERS: '/offers',
  GIFT_CARD: '/gift-card',
  COUPON: '/coupon',
  BRAND: '/brand',
  PINCODE_MAPPING: '/pincode-mapping',
  MATERIAL: '/material',
  CATEGORY: '/category',
  AGE_GROUP: '/age-group',
  BIWEEKLY: '/15-days',
  FACILITATES: '/facilitate',
  TAGS: '/tag',
  GENRES: '/genre',
  BANNER: '/banner',
  COLLECTION: '/collection',
  CONFIGS: '/configs',
  REFERRAL: '/referral',
  ENCASHMENT: '/encashment',
  CUSTOM_REPORTS: '/custom-reports',
  SEMI_ANNUAL: '/6-months',
  INVOICES: '/invoices',
  HOME_VIDEO: '/home-video',
  CONTENT_MASTER: '/content-master',
  UI_LABELS: '/ui-labels',
  PACKAGING_MATERIAL: '/packaging-material',
  CONFIRM_ORDER: '/confirm-order',
  ASSIGN_DELIVERY_PARTNER: '/assign-delivery-partner',
  NEW_PLANS: '/new-plans',
  RACK_MASTER: '/rack-master',
  SUBSCRIPTION_HISTORY: '/subscription-history',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  MOVIES: 'Movies',
  SAMPLE: 'Sample',
  PAYMENTS: 'Payments',
  REQUESTS: 'Requests',
  ORDERS: 'Orders',
  NEW: 'New',
  ALL: 'All',
  REPORTS: 'Reports',
  BULK_UPDATE: 'Bulk Update',
  HOLDING_PERIOD: 'Holding Period',
  UNFULFILLED_ORDERS: 'Unfulfilled Orders',
  MASTER_LISTS: 'Master Lists',
  APP_CONTENT: 'App Content',
  STORY: 'Story',
  TOY: 'Toy',
  PINCODE: 'Pincode',
  SYSTEM_USERS: 'System Users',
  ADMINS: 'Admins',
  END_USERS: 'End Users',
  DELIVERY_PARTNERS: 'Delivery Partners',
  SUBSCRIPTION_PLANS: 'Subscription Plans',
  TRIAL: 'Trial',
  GARDEN: 'Garden',
  ESTATE: 'Estate',
  FOREST: 'Forest',
  END_USER: 'End User',
  LIBRARY: 'Library',
  PROFILE: 'Profile',
  LOGOUT: 'Logout',
  EXPORTS: 'Exports',
  RAISE_REQUEST: 'Raise Request',
  SUPPORT: 'Support',
  INVENTORY: 'Inventory',
  NOTIFICATIONS: 'Notifications',
  OFFERS: 'Offers',
  GIFT_CARD: 'Gift Card',
  COUPON: 'Coupon',
  BRAND: 'Brand',
  PINCODE_MAPPING: 'Pincode Mapping',
  MATERIAL: 'Material',
  CATEGORY: 'Category',
  AGE_GROUP: 'Age Group',
  FACILITATES: 'Facilitate',
  TAGS: 'Tag',
  GENRES: 'Genre',
  BANNER: 'Banner',
  COLLECTION: 'Collection',
  CONFIGS: 'Configs',
  CUSTOM_REPORTS: 'Custom Reports',
  REFERRAL: 'Referral',
  HOME_VIDEO: 'Home Video',
  CONTENT_MASTER: 'Content Master',
  UI_LABELS: 'Ui Labels',
  CONFIRM_ORDER: 'Confirm Order',
  ASSIGN_DELIVERY_PARTNER: 'Assign Delivery Partner',
  PACKAGING_MATERIAL: 'Packaging Material',
  NEW_PLANS: 'New Plans',
  RACK_MASTER: 'Rack Master',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super Admin',
  POWER_ADMIN: 'Power Admin',
  BASIC_ADMIN: 'Basic Admin',
  DELIVERY: 'Delivery Manager',
  LIBRARIAN: 'Librarian',
  USER: 'User',
  ORDER_INVENTORY_ADMIN: 'Order & Inventory Manager',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^([A-Za-z0-9_\-.+])+@(?!(?:[A-Za-z0-9_\-.]+\.)?([A-Za-z]{2,4})\.\2)(?!.*\.\.)[A-Za-z0-9_\-.]+\.([A-Za-z]{2,4})$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  POSITIVE_NUMBERS: /^(0|[1-9]\d*)$/,
  PRICE: /^(?!0+(\.0*)?$)\d+(\.\d{1,2})?$/,
  NOT_DIGIT_CHARACTER: /[^\d.-]/g,
  MASK_4DIGIT: /.(?=.{4,}$)/g,
  PINCODE: /^[1-9]{1}[0-9]{5}$/,
  IS_DIGIT: /^-?\d+(\.\d*)?$/,
  NOT_NUM_STRING: /^[^\d]*$/,
  COUPON: /^[\w\d-]{1,}$/,
  NOT_ALLOW_SPECIAL_CHAR: /[^A-Z0-9]/g,
  ALLOW_STRING_NUM_UNDERSCORE: /[^A-Z0-9_]/g,
};

export const LIMIT = 10;
export const SCROLL_PERCENT = 0.85;

export const SORT = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const STATUS = {
  ACTIVE: { label: 'Active', color: 'green', key: true },
  INACTIVE: { label: 'Inactive', color: 'red', key: false },
};

export const ADMIN_ROLES = {
  POWER_ADMIN: { label: 'Power Admin', key: 'POWER_ADMIN' },
  BASIC_ADMIN: { label: 'Basic Admin', key: 'BASIC_ADMIN' },
  ORDER_INVENTORY_ADMIN: {
    label: 'Order & Inventory Manager',
    key: 'ORDER_INVENTORY_ADMIN',
  },
};

export const STATUS_FILTER = {
  PENDING: { label: 'Pending', key: 'PENDING' },
  APPROVED: { label: 'Approved', key: 'APPROVED' },
  REJECTED: {
    label: 'Rejected',
    key: 'REJECTED',
  },
};

export const STATUS_LIBRARY_DAMAGE_FILTER = {
  PENDING: { label: 'Pending', key: 'PENDING' },
  RESOLVED: {
    label: 'Resolved',
    key: 'RESOLVED',
  },
  APPROVED: { label: 'Accepted', key: 'APPROVED' },
};

export const STATUS_REQUEST_ENCASHMENT_FILTER = {
  APPROVED: {
    label: 'Paid',
    key: 'APPROVED',
  },
  PENDING: { label: 'Unpaid', key: 'PENDING' },
  REJECTED: { label: 'Rejected', key: 'REJECTED' },
};

export const USER_ROLES = {
  SUPER_ADMIN: { label: 'Super Admin', key: 'SUPER_ADMIN' },
  POWER_ADMIN: { label: 'Power Admin', key: 'POWER_ADMIN' },
  BASIC_ADMIN: { label: 'Basic Admin', key: 'BASIC_ADMIN' },
  USER: { label: 'User', key: 'USER' },
  DELIVERY: { label: 'Delivery', key: 'DELIVERY' },
  LIBRARIAN: { label: 'Librarian', key: 'LIBRARIAN' },
  ORDER_INVENTORY_ADMIN: {
    label: 'Order & Inventory Manager',
    key: 'ORDER_INVENTORY_ADMIN',
  },
};

export const ARCHIVING_STATE = {
  ARCHIVED: 'Archived',
  UNARCHIVED: 'Unarchived',
};
export const PUBLISH_STATE = {
  PUBLISH: 'Publish',
  UNPUBLISH: 'Unpublish',
};
export const ORDER = [
  { name: 'Ascending', value: 'ASC' },
  { name: 'Descending', value: 'DESC' },
];

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const ALLOW_IMAGE_TYPES = ['jpeg', 'jpg', 'png', 'gif'];
export const ALLOW_IMAGES_TYPES = ['jpeg', 'jpg', 'png'];
export const ALLOW_VIDEO_TYPES = ['mp4'];

export const ALLOW_CSV_FILE = ['csv'];

export const TABLE_HEIGHT = 225;
export const TABLE_HEIGHT_SEARCH = 395;

export const ROLE_FORM_OPTIONS = [
  { label: 'Power Admin', value: 'POWER_ADMIN' },
  { label: 'Basic Admin', value: 'BASIC_ADMIN' },
  {
    label: 'Order & Inventory Manager',
    value: 'ORDER_INVENTORY_ADMIN',
  },
];

export const USER_FORM_STATUS = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
];

export const TABS = {
  BASIC_DETAILS: { label: 'Basic Details', key: 'BASIC_DETAILS' },
  INVENTORY_DETAILS: { label: 'Inventory Details', key: 'INVENTORY_DETAILS' },
  SUBSCRIPTION_PLAN_DETAILS: {
    label: 'Subscription Plan Details',
    key: 'SUBSCRIPTION_PLAN_DETAILS',
  },
  ORDER_DETAILS: { label: 'Order Details', key: 'ORDER_DETAILS' },
  TRANSACTION_DETAILS: {
    label: 'Transaction Details',
    key: 'TRANSACTION_DETAILS',
  },
  BIWEEKLY: { label: '15 Days', key: 'BIWEEKLY' },
  MONTHLY: { label: 'Monthly', key: 'MONTHLY' },
  QUARTERLY: { label: '3 Months', key: 'QUARTERLY' },
  ANNUALLY: { label: '12 Months', key: 'ANNUALLY' },
  DELIVERY: { label: 'Delivery', key: 'DELIVERY' },
  RETURN: { label: 'Return', key: 'RETURN' },
  SUGGESTED_TOY: { label: 'Suggested Toy', key: 'SUGGESTED_TOY' },
  REPORT_DAMAGE: { label: 'Report Damage', key: 'REPORT_DAMAGE' },
  CHANGE_ADDRESS: { label: 'Change Address', key: 'CHANGE_ADDRESS' },
  DAMAGES: { label: 'Damages / Missing', key: 'DAMAGES' },
  CLOSURE_REQUESTS: { label: 'Closure Requests', key: 'CLOSURE_REQUESTS' },
  BAGS: { label: 'Packaging Materials', key: 'BAGS' },
  NEW_TOYS: { label: 'New Toys', key: 'NEW_TOYS' },
  INFLOW: { label: 'Inflow', key: 'INFLOW' },
  OUTFLOW: { label: 'Outflow', key: 'OUTFLOW' },
  REFERRAL: { label: 'Referral', key: 'REFERRAL' },
  ENCASHMENT: { label: 'Encashment', key: 'ENCASHMENT' },
  SEMI_ANNUAL: { label: '6 Months', key: 'SEMI_ANNUAL' },
  INVOICES: { label: 'Invoices', key: 'INVOICES' },
  CONFIRM_ORDER: { label: 'Confirm Order', key: 'CONFIRM_ORDER' },
  ASSIGN_DELIVERY_PARTNER: {
    label: 'Assign Delivery Partner',
    key: 'ASSIGN_DELIVERY_PARTNER',
  },
  SUBSCRIPTION_HISTORY: {
    label: 'Subscription History',
    key: 'SUBSCRIPTION_HISTORY',
  },
};

export const GENDERS = [
  {
    value: 'MALE',
    label: 'Male',
  },
  {
    value: 'FEMALE',
    label: 'Female',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];

export const DATE_FORMATS = {
  REGULAR: 'DD/MM/YYYY',
  ONBOARD: 'DD.MM.YYYY',
  WITH_TIME: 'DD/MM/YYYY | h:mm A',
};

export const TIME_FORMATS = {
  REGULAR: 'h:mm A',
};

export const EXPORT_TYPES = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  DELIVERY_PARTNER: 'DELIVERY_PARTNER',
  LIBRARY: 'LIBRARY',
  LIBRARY_INVENTORY: 'LIBRARY_INVENTORY',
  TOY: 'TOY',
  LIBRARY_CLOSURE: 'LIBRARY_CLOSURE',
  LIBRARY_DAMAGE: 'LIBRARY_DAMAGE',
  NEW_TOY: 'NEW_TOY',
  BAGS: 'BAGS',
  TOY_BULK_UPLOAD: 'TOY_BULK_UPLOAD',
  PINCODE: 'PINCODE',
  CHANGE_ADDRESS: 'CHANGE_ADDRESS',
  USER_REPORT_DAMAGE: 'USER_REPORT_DAMAGE',
  TOY_SUGGESTION: 'TOY_SUGGESTION',
  ORDER: 'ORDER',
  INFLOW: 'INFLOW',
  OUTFLOW: 'OUTFLOW',
  RETURN_ORDER: 'RETURN_ORDER',
  NEW_ORDERS: 'NEW_ORDERS',
  NEW_RETURN_ORDERS: 'NEW_RETURN_ORDERS',
  UNFULFILLED_ORDERS: 'UNFULFILLED_ORDERS',
  HOLDING_PERIOD: 'HOLDING_PERIOD',
  ORDER_CHALLANS: 'ORDER_CHALLANS',
  ORDER_STICKERS: 'ORDER_STICKERS',
  REVENUE_REPORTS: 'REVENUE_REPORTS',
  TOY_REPORTS: 'TOY_REPORTS',
  GIFT_CARD: 'GIFT_CARD',
  COUPON: 'COUPON',
  REFERRAL_TRANSACTION: 'REFERRAL_TRANSACTION',
};

export const PLAN_NAMES = {
  GARDEN: { key: 'Garden', value: 'GARDEN' },
  ESTATE: { key: 'Estate', value: 'ESTATE' },
  FOREST: { key: 'Forest', value: 'FOREST' },
  TRIAL: { key: 'Trial', value: 'TRIAL' },
};

export const PLAN_TYPES = {
  BIWEEKLY: { key: '15 Days', value: 'BIWEEKLY' },
  MONTHLY: { key: 'Monthly', value: 'MONTHLY' },
  QUARTERLY: { key: '3 Months', value: 'QUARTERLY' },
  SEMI_ANNUAL: { key: '6 Months', value: 'SEMI_ANNUAL' },
  ANNUALLY: { key: '12 Months', value: 'ANNUALLY' },
};

export const EXPORT_JOBS_STATUS = {
  PENDING: { text: 'Pending', color: 'yellow', value: 'PENDING' },
  EXPORTING: { text: 'Exporting', color: 'blue', value: 'EXPORTING' },
  COMPLETED: { text: 'Completed', color: 'green', value: 'COMPLETED' },
  FAILED: { text: 'Failed', color: 'red', value: 'FAILED' },
};

export const FULFILLMENT_STATUS = {
  FULFILLED: { text: 'Fulfilled', color: 'green', value: 'FULFILLED' },
  UNFULFILLED: { text: 'Unfulfilled', color: 'red', value: 'UNFULFILLED' },
  CANCELLED: {
    text: 'Cancelled',
    color: '#808080',
    value: 'CANCELLED',
  },
};

export const TRANSACTION_HISTORY_TYPES = {
  SUBSCRIPTION_PURCHASED: {
    key: 'Subscription',
    value: 'SUBSCRIPTION_PURCHASED',
  },
  SUBSCRIPTION_RENEWED: { key: 'Subscription', value: 'SUBSCRIPTION_RENEWED' },
  DELIVERY_CHARGED: { key: 'Delivery Charges', value: 'DELIVERY_CHARGED' },
  SECURITY_DEPOSIT: { key: 'Security Deposit', value: 'SECURITY_DEPOSIT' },
  REFUND_DELIVERY_CHARGED: {
    key: 'Delivery Refund',
    value: 'REFUND_DELIVERY_CHARGED',
  },
  REFUND_REQUEST: {
    key: 'Delivery Refund',
    value: 'REFUND_REQUEST',
  },
  REFERRAL: {
    key: 'Referral',
    value: 'REFERRAL',
  },
  ENCASHMENT: {
    key: 'Encashment',
    value: 'ENCASHMENT',
  },
};

export const REQUEST_STATUS = {
  PENDING: { text: 'Pending', color: 'yellow', value: 'PENDING' },
  APPROVED: { text: 'Approved', color: 'green', value: 'APPROVED' },
  REJECTED: { text: 'Rejected', color: 'red', value: 'REJECTED' },
  RESOLVED: { text: 'Resolved', color: 'green', value: 'RESOLVED' },
};

export const DAMAGE_REQUEST_STATUS = {
  PENDING: { text: 'Pending', color: 'yellow', value: 'PENDING' },
  APPROVED: { text: 'Accepted', color: 'green', value: 'APPROVED' },
  REJECTED: { text: 'Rejected', color: 'red', value: 'REJECTED' },
  RESOLVED: { text: 'Resolved', color: 'green', value: 'RESOLVED' },
};

export const REQUEST_ENCASHMENT_STATUS = {
  PENDING: { text: 'Unpaid', color: 'red', value: 'PENDING' },
  APPROVED: { text: 'Paid', color: 'green', value: 'APPROVED' },
  REJECTED: { text: 'Rejected', color: 'blue', value: 'REJECTED' },
};

export const PAYMENT_STATUS = {
  PAID: { text: 'Paid', color: 'green', value: 'PAID' },
  UNPAID: { text: 'Unpaid', color: 'red', value: 'UNPAID' },
  CARRY_FORWARDED: {
    text: 'Carry Forwarded',
    color: 'blue',
    value: 'CARRY_FORWARDED',
  },
};

export const REFERRAL_PAYMENT_STATUS = {
  PAID: { text: 'Paid', color: 'green', value: 'PAID' },
  UNPAID: { text: 'Unpaid', color: 'red', value: 'UNPAID' },
  REJECTED: { text: 'Rejected', color: 'blue', value: 'REJECTED' },
};

export const DELIVERY_TYPE = {
  FREE: { label: 'Free', value: 'FREE' },
  PAID: { label: 'Paid', value: 'PAID' },
  BONUS: { label: 'Bonus', value: 'BONUS' },
};

export const RETURN_ORDER_ITEM_STATUS = {
  RETURN_REQUESTED: { label: 'Return requested', value: 'RETURN_REQUESTED' },
  READY_TO_PICKUP: { label: 'Ready to pickup', value: 'READY_TO_PICKUP' },
  PICKED_UP: { label: 'Picked-up', value: 'PICKED_UP' },
  RETURNED: { label: 'Returned', value: 'RETURNED' },
  RETURN_CONFIRMED: { label: 'Return confirmed', value: 'RETURN_CONFIRMED' },
};

export const RETURN_CONTENT_MASTER_ORDER_ITEM_STATUS = {
  RETURN_REQUESTED: { label: 'Return requested', value: 'RETURN_REQUESTED' },
  READY_TO_PICKUP: { label: 'Ready to pickup', value: 'READY_TO_PICKUP' },
  PICKED_UP: { label: 'Picked-up', value: 'PICKED_UP' },
  RETURNED: { label: 'Returned', value: 'RETURNED' },
  RETURN_CONFIRMED: { label: 'Return confirmed', value: 'RETURN_CONFIRMED' },
  LIBRARY_DAMAGE: { label: 'Library Damage', value: 'LIBRARY_DAMAGE' },
};

export const REQUEST_TYPES = {
  CHANGE_ADDRESS: 'CHANGE_ADDRESS',
  TOY_SUGGESTION: 'TOY_SUGGESTION',
  LIBRARY_CLOSURE: 'LIBRARY_CLOSURE',
  BAGS: 'BAGS',
  NEW_TOY: 'NEW_TOY',
  RECEIVED_DAMAGED: 'RECEIVED_DAMAGED',
  DAMAGED_DURING_RENTAL_PERIOD: 'DAMAGED_DURING_RENTAL_PERIOD',
  LIBRARY_DAMAGE: 'LIBRARY_DAMAGE',
  ENCASHMENT: 'ENCASHMENT',
};

export const SIZE = {
  MASTER_CARTON_1: {
    label: 'Master Carton - 1 (25.5*17.5*28cm)',
    value: 'MASTER_CARTON_1',
    url: 'https://static.theelefant.com/bags/mastercarton_255*175*28.png',
  },
  MASTER_CARTON_2: {
    label: 'Master Carton - 2 (29.5*30*39.5cm)',
    value: 'MASTER_CARTON_2',
    url: 'https://static.theelefant.com/bags/mastercarton-2_295*30*395.png',
  },
  MASTER_CARTON_3: {
    label: 'Master Carton - 3 (33.5*30*39.5cm)',
    value: 'MASTER_CARTON_3',
    url: 'https://static.theelefant.com/bags/mastercarton-3_335*30*395cm.png',
  },
  PIZZA_STYLE_1: {
    label: 'PIZZA STYLE - 1 (28*27*9cm)',
    value: 'PIZZA_STYLE_1',
    url: 'https://static.theelefant.com/bags/pizzastyle-1_28*27*9cm.png',
  },
  PIZZA_STYLE_2: {
    label: 'PIZZA STYLE -2 (35.5*35.5*8cm)',
    value: 'PIZZA_STYLE_2',
    url: 'https://static.theelefant.com/bags/pizzastyle-2_355*355*8cm.png',
  },
  PIZZA_STYLE_3: {
    label: 'PIZZA STYLE-3 (52*31*9cm)',
    value: 'PIZZA_STYLE_3',
    url: 'https://static.theelefant.com/bags/pizzastyle-3_52*31*9cm.png',
  },
  CAKE_STYLE: {
    label: 'CAKE STYLE (19*18*12cm)',
    value: 'CAKE_STYLE',
    url: 'https://static.theelefant.com/bags/cakestyle_19*18*12cm.png',
  },
  UV_STERILIZER: {
    label: 'UV Sterilizer',
    value: 'UV_STERILIZER',
    url: 'https://static.theelefant.com/bags/uv_sterilizer.png',
  },
  WRAPPING_SHEET: {
    label: 'Wrapping Sheet',
    value: 'WRAPPING_SHEET',
    url: 'https://static.theelefant.com/bags/wrapping_sheet.png',
  },
  NON_WOVEN_POTLI_BAG_SMALL: {
    label: 'Non Woven Potli Bag -Small',
    value: 'NON_WOVEN_POTLI_BAG_SMALL',
    url: 'https://static.theelefant.com/bags/non-woven-potli-bag-small.png',
  },
  NON_WOVEN_POTLI_BAG_MEDIUM: {
    label: 'Non Woven Potli Bag - Medium',
    value: 'NON_WOVEN_POTLI_BAG_MEDIUM',
    url: 'https://static.theelefant.com/bags/non-woven-potli-bag-medium.png',
  },
  NON_WOVEN_POTLI_BAG_BIG: {
    label: 'Non Woven Potli Bag - Big',
    value: 'NON_WOVEN_POTLI_BAG_BIG',
    url: 'https://static.theelefant.com/bags/non-woven-potli-bag-big.png',
  },
  TAPE_1_INCH_65_MTR_COLOR_PRINTED: {
    label: 'TAPE 1 INCH 65MTR - COLOR PRINTED',
    value: 'TAPE_1_INCH_65_MTR_COLOR_PRINTED',
    url:
      'https://static.theelefant.com/bags/tape-1-inch-65-mtr-color-printed.png',
  },
  TAPE_3_INCH_65_MTR_COLOR_PRINTED: {
    label: 'TAPE 3 INCH 65MTR - COLOR PRINTED',
    value: 'TAPE_3_INCH_65_MTR_COLOR_PRINTED',
    url:
      'https://static.theelefant.com/bags/tape-3-inch-65-mtr-color-printed.png',
  },
  STICKER_THE_ELEPHANT_ROUND_SHAPED: {
    label: 'Sticker (The Elephant) Round Shaped',
    value: 'STICKER_THE_ELEPHANT_ROUND_SHAPED',
    url:
      'https://static.theelefant.com/bags/sticker_the_elephant_round-shaped.png',
  },
  BOOKSHELVES_PAIR_BLACK_7: {
    label: "Bookshelves Pair-Black 7'",
    value: 'BOOKSHELVES_PAIR_BLACK_7',
    url: 'https://static.theelefant.com/bags/bookshelves-pair-black-7.png',
  },
  BOOKSHELVES_PAIR_PINK_7: {
    label: "Bookshelves Pair-Pink 7'",
    value: 'BOOKSHELVES_PAIR_PINK_7',
    url: 'https://static.theelefant.com/bags/bookshelves-pair-pink-7.png',
  },
};
export const MAX_BANNER_IMAGE_SIZE = 3 * 1024 * 1024;
export const MAX_BRANDS_IMAGE_SIZE = 300 * 1024;
export const MAX_GENRE_IMAGE_SIZE = 200 * 1024;
export const MAX_IMAGE_SIZE = 15 * 1024 * 1024;
export const MAX_VIDEO_SIZE = 30 * 1024 * 1024;
export const MAX_STORY_VIDEO_SIZE = 20 * 1024 * 1024;

export const SIDEBAR_ADMIN_DROPDOWNS = [
  'payments',
  'requests',
  'orders',
  'reports',
  'master-lists',
  'app-content',
  'system-users',
  'subscription-plans',
  'offers',
];

export const SIDEBAR_LIBRARY_DROPDOWNS = ['orders', 'offers'];

export const ORDER_STATUS = {
  AWAITING_PAYMENT: {
    text: 'Awaiting Payment',
    color: '#CCCCCC',
    value: 'AWAITING_PAYMENT',
  },
  PAYMENT_FAILED: {
    text: 'Payment Failed',
    color: '#FF0000',
    value: 'PAYMENT_FAILED',
  },
  PROCESSING: { text: 'Processing', color: '#007BFF', value: 'PROCESSING' },
  PLACED: { text: 'Placed', color: '#00FF00', value: 'PLACED' },
  CONFIRMED: { text: 'Confirmed', color: '#008000', value: 'CONFIRMED' },
  READY_TO_SHIP: {
    text: 'Ready to Ship',
    color: '#FFA500',
    value: 'READY_TO_SHIP',
  },
  SHIPPED: { text: 'Shipped', color: '#800080', value: 'SHIPPED' },
  DELIVERED: { text: 'Delivered', color: '#006400', value: 'DELIVERED' },
  CANCELLED: { text: 'Cancelled', color: '#808080', value: 'CANCELLED' },
};

export const ORDER_TYPE = {
  NEW: 'NEW',
  ALL: 'ALL',
};

export const EXPORT = {
  USER: 'User',
  ADMIN: 'Admin',
  DELIVERY_PARTNER: 'Delivery Partner',
  LIBRARY: 'Library',
  LIBRARY_INVENTORY: 'Library Inventory',
  TOY: 'Toy',
  LIBRARY_CLOSURE: 'Closure Requests',
  LIBRARY_DAMAGE: 'Damages',
  NEW_TOY: 'New Toy',
  BAGS: 'Packaging Materials',
  PINCODE: 'Pincode Mapping',
  CHANGE_ADDRESS: 'Change Address',
  USER_REPORT_DAMAGE: 'Report Damage',
  TOY_SUGGESTION: 'Suggested Toy',
  ORDER: 'Order',
  INFLOW: 'Inflow',
  OUTFLOW: 'Outflow',
  RETURN_ORDER: 'Return Order',
  NEW_ORDERS: 'New Orders',
  NEW_RETURN_ORDERS: 'New Return Orders',
  UNFULFILLED_ORDERS: 'Unfulfilled Orders',
  HOLDING_PERIOD: 'Holding period',
  ORDER_CHALLANS: 'Order Challan',
  ORDER_STICKERS: 'Order Sticker',
  REVENUE_REPORTS: 'Revenue Reports',
  TOY_REPORTS: 'Toy Reports',
  TOY_BULK_UPLOAD: 'Toy Bulk Upload',
  GIFT_CARD: 'Gift Card',
  COUPON: 'Coupon',
  REFERRAL_TRANSACTION: 'End User Referral',
};

export const INVOICE_REASON = [
  {
    value: 'Service Fee',
    label: 'Service Fee',
  },
  {
    value: 'Referral Fee',
    label: 'Referral Fee',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const REFUND_REASON = [
  {
    value: 'Delivery Refund',
    label: 'Delivery Refund',
  },
  {
    value: 'Payment Failed Refund',
    label: 'Payment Failed Refund',
  },
  {
    value: 'Special Refund',
    label: 'Special Refund',
  },
  {
    value: 'Security Deposit',
    label: 'Security Deposit',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const PAYMENT_STATUS_OPTIONS = [
  { label: 'Paid', value: 'PAID' },
  { label: 'Unpaid', value: 'UNPAID' },
];

export const REPORT_DAMAGE_OPTIONS = [
  {
    label: 'Damaged during rental period',
    value: 'DAMAGED_DURING_RENTAL_PERIOD',
  },
  { label: 'Received Damaged', value: 'RECEIVED_DAMAGED' },
];
export const COLLECTION_ORDER = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const GENRES_ORDER = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
];
export const DELIVERY_TYPE_OPTIONS = [
  { label: 'Free', value: 'FREE' },
  { label: 'Paid', value: 'PAID' },
  { label: 'Bonus', value: 'BONUS' },
];

export const NEW_ORDER_TABLE_ACTIONS_OPTIONS = [
  { label: 'Mark as ready', value: 'FULFILLED' },
  { label: 'Mark as cancelled', value: 'UNFULFILLED' },
];

export const RETURN_ORDER_TABLE_ACTIONS_OPTIONS = [
  { label: 'Confirm Return', value: 'RETURN_CONFIRMED' },
  { label: 'Report Damage', value: 'REPORT_DAMAGE' },
];

export const LIBRARY_ALL_DELIVERY_TABLE_ACTIONS_OPTIONS = [
  { label: 'Mark as cancelled', value: 'CANCELLED' },
];

export const NEW_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS = [
  { label: 'Mark as fulfilled', value: 'FULFILLED' },
  { label: 'Mark as unfulfilled', value: 'UNFULFILLED' },
];

export const ALL_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS = [
  { label: 'View Content Logs', value: 'VIEW_CONTENT_LOGS' },
];

export const RETURN_ORDER_CARD_ACTIONS_OPTIONS = {
  VIEW_CONTENT_LOGS: { label: 'View Content Logs', value: 'VIEW_CONTENT_LOGS' },
  RETURN_CONFIRMED: { label: 'Confirm Return', value: 'RETURN_CONFIRMED' },
  REPORT_DAMAGE: { label: 'Report Damage', value: 'REPORT_DAMAGE' },
};

export const NEW_ORDER_REASON = [
  { label: 'Toy Damaged', value: 'Toy Damaged' },
  { label: 'Unable To Locate', value: 'Unable To Locate' },
  { label: 'Toy Not Sanitized', value: 'Toy Not Sanitized' },
  { label: 'Not Available To Handover', value: 'Not Available To Handover' },
];

export const DAMAGE_TABLE_ACTIONS_OPTIONS = {
  ACCEPT: { label: 'Accept', value: 'ACCEPT' },
  VIEW_CONTENT_LOG: { label: 'View Content Logs', value: 'VIEW_CONTENT_LOGS' },
};

export const PRODUCT_RATE = {
  LIKE: 'LIKE',
  DISLIKE: 'DISLIKE',
};

export const INFLOW_DESCRIPTION = [
  {
    label: 'Subscription',
    value: ['SUBSCRIPTION_PURCHASED', 'SUBSCRIPTION_RENEWED'],
  },
  { label: 'Delivery Charges', value: 'DELIVERY_CHARGED' },
];

export const OUTFLOW_DESCRIPTION = [
  {
    label: 'Delivery Refund',
    value: ['REFUND_DELIVERY_CHARGED', 'REFUND_REQUEST'],
  },
  { label: 'Security Deposit', value: 'SECURITY_DEPOSIT' },
];

export const REFERRAL_DESCRIPTION = [
  {
    label: 'Referral',
    value: 'REFERRAL',
  },
  { label: 'Encashment', value: 'ENCASHMENT' },
];

export const REPORT_DAMAGE_REQUEST_TYPES = {
  RECEIVED_DAMAGED: { label: 'Received Damaged', value: 'RECEIVED_DAMAGED' },
  DAMAGED_DURING_RENTAL_PERIOD: {
    label: 'Damaged during rental period',
    value: 'DAMAGED_DURING_RENTAL_PERIOD',
  },
};

export const PLAN_TYPES_OPTIONS = [
  { value: 'BIWEEKLY', label: '15 Days' },
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'QUARTERLY', label: '3 Months' },
  { value: 'SEMI_ANNUAL', label: '6 Months' },
  { value: 'ANNUALLY', label: '12 Months' },
];

export const PLAN_NAMES_OPTIONS = [
  { value: 'GARDEN', label: 'Garden' },
  { value: 'ESTATE', label: 'Estate' },
  { value: 'FOREST', label: 'Forest' },
  { value: 'TRIAL', label: 'Trial' },
];

export const PERMISSIONS_KEY = {
  OVERVIEW: 'OVERVIEW',
  PAYMENTS_END_USER_INFLOW: 'PAYMENTS_END_USER_INFLOW',
  PAYMENTS_END_USER_OUTFLOW: 'PAYMENTS_END_USER_OUTFLOW',
  PAYMENTS_LIBRARY: 'PAYMENTS_LIBRARY',
  PAYMENTS_LIBRARY_REFERRAL: 'PAYMENTS_LIBRARY_REFERRAL',
  REQUESTS_END_USER_SUGGESTED_TOY: 'REQUESTS_END_USER_SUGGESTED_TOY',
  REQUESTS_END_USER_CHANGE_ADDRESS: 'REQUESTS_END_USER_CHANGE_ADDRESS',
  REQUESTS_END_USER_REPORT_DAMAGE: 'REQUESTS_END_USER_REPORT_DAMAGE',
  REQUESTS_LIBRARY_DAMAGES: 'REQUESTS_LIBRARY_DAMAGES',
  REQUESTS_LIBRARY_CLOSURE_REQUESTS: 'REQUESTS_LIBRARY_CLOSURE_REQUESTS',
  REQUESTS_LIBRARY_BAGS: 'REQUESTS_LIBRARY_BAGS',
  REQUESTS_LIBRARY_NEW_TOYS: 'REQUESTS_LIBRARY_NEW_TOYS',
  ORDERS_NEW_DELIVERY: 'ORDERS_NEW_DELIVERY',
  ORDERS_NEW_RETURN: 'ORDERS_NEW_RETURN',
  ORDERS_ALL_DELIVERY: 'ORDERS_ALL_DELIVERY',
  ORDERS_ALL_RETURN: 'ORDERS_ALL_RETURN',
  REPORTS_BULK_UPDATE: 'REPORTS_BULK_UPDATE',
  REPORTS_HOLDING_PERIOD: 'REPORTS_HOLDING_PERIOD',
  REPORTS_UNFULFILLED_ORDERS: 'REPORTS_UNFULFILLED_ORDERS',
  MASTER_LIST_LIBRARY: 'MASTER_LIST_LIBRARY',
  MASTER_LIST_LIBRARY_BASIC_DETAILS: 'MASTER_LIST_LIBRARY_BASIC_DETAILS',
  MASTER_LIST_LIBRARY_INVENTORY_DETAILS:
    'MASTER_LIST_LIBRARY_INVENTORY_DETAILS',
  MASTER_LIST_TOY: 'MASTER_LIST_TOY',
  MASTER_LIST_PINCODE: 'MASTER_LIST_PINCODE',
  SYSTEM_USERS_ADMINS: 'SYSTEM_USERS_ADMINS',
  SYSTEM_USERS_END_USERS: 'SYSTEM_USERS_END_USERS',
  SYSTEM_USERS_DELIVERY_PARTNERS: 'SYSTEM_USERS_DELIVERY_PARTNERS',
  SUBSCRIPTION_PLANS: 'SUBSCRIPTION_PLANS',
  OFFERS_COUPONS: 'OFFERS_COUPONS',
  OFFERS_GIFT_CARD: 'OFFERS_GIFT_CARD',
  NOTIFICATIONS: 'NOTIFICATIONS',
  EXPORTS: 'EXPORTS',
  MASTER_LIST_BRAND: 'MASTER_LIST_BRAND',
  MASTER_LIST_MATERIAL: 'MASTER_LIST_MATERIAL',
  MASTER_LIST_CATEGORY: 'MASTER_LIST_CATEGORY',
  MASTER_LIST_AGE_GROUP: 'MASTER_LIST_AGE_GROUP',
  MASTER_LIST_FACILITATE: 'MASTER_LIST_FACILITATE',
  MASTER_LIST_CONTENT: 'MASTER_LIST_CONTENT',
  MASTER_LIST_TAG: 'MASTER_LIST_TAG',
  APP_CONTENT_GENRES: 'APP_CONTENT_GENRES',
  APP_CONTENT_BANNER: 'APP_CONTENT_BANNER',
  APP_CONTENT_COLLECTION: 'APP_CONTENT_COLLECTION',
  APP_CONTENT_STORY: 'APP_CONTENT_STORY',
  CONFIGS: 'CONFIGS',
  REQUESTS_END_USER_ENCASHMENT: 'REQUESTS_END_USER_ENCASHMENT',
  PAYMENTS_END_USER_REFERRAL: 'PAYMENTS_END_USER_REFERRAL',
  REPORTS_CUSTOM: 'REPORTS_CUSTOM',
  OFFERS_REFERRAL_USERS: 'OFFERS_REFERRAL_USERS',
  VIDEOS: 'VIDEOS',
  UI_LABELS: 'UI_LABELS',
  ORDERS_NEW_PLACED: 'ORDERS_NEW_PLACED',
  ORDERS_NEW_CONFIRMED: 'ORDERS_NEW_CONFIRMED',
  MASTER_LIST_BAG: 'MASTER_LIST_BAG',
  MASTER_LIST_RACK: 'MASTER_LIST_RACK',
};

export const EXPORT_TYPES_SELECT = [
  {
    value: 'ALL',
    label: 'All',
    permissionKey: PERMISSIONS_KEY?.EXPORTS,
  },
  {
    value: 'USER',
    label: 'User',
    permissionKey: PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS,
  },
  {
    value: 'GENRE',
    label: 'Genre',
    permissionKey: PERMISSIONS_KEY?.APP_CONTENT_GENRES,
  },
  {
    value: 'COLLECTION',
    label: 'Collection',
    permissionKey: PERMISSIONS_KEY?.APP_CONTENT_COLLECTION,
  },
  {
    value: 'COLLECTION',
    label: 'Collection',
    permissionKey: PERMISSIONS_KEY?.APP_CONTENT_STORY,
  },
  {
    value: 'BANNER',
    label: 'Banner',
    permissionKey: PERMISSIONS_KEY?.APP_CONTENT_BANNER,
  },
  {
    value: 'ADMIN',
    label: 'Admin',
    permissionKey: PERMISSIONS_KEY?.SYSTEM_USERS_ADMINS,
  },
  {
    value: 'DELIVERY_PARTNER',
    label: 'Delivery Partner',
    permissionKey: PERMISSIONS_KEY?.SYSTEM_USERS_DELIVERY_PARTNERS,
  },
  {
    value: 'LIBRARY',
    label: 'Library',
    permissionKey: PERMISSIONS_KEY?.MASTER_LIST_LIBRARY,
  },
  {
    value: 'LIBRARY_INVENTORY',
    label: 'Library Inventory',
    permissionKey: PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_INVENTORY_DETAILS,
  },
  {
    value: 'TOY',
    label: 'Toy',
    permissionKey: PERMISSIONS_KEY?.MASTER_LIST_TOY,
  },
  {
    value: 'LIBRARY_CLOSURE',
    label: 'Closure Requests',
    permissionKey: PERMISSIONS_KEY?.REQUESTS_LIBRARY_CLOSURE_REQUESTS,
  },
  {
    value: 'LIBRARY_DAMAGE',
    label: 'Damages',
    permissionKey: PERMISSIONS_KEY?.REQUESTS_LIBRARY_DAMAGES,
  },
  {
    value: 'NEW_TOY',
    label: 'New Toy',
    permissionKey: PERMISSIONS_KEY?.REQUESTS_LIBRARY_NEW_TOYS,
  },
  {
    value: 'BAGS',
    label: 'Packaging Materials',
    permissionKey: PERMISSIONS_KEY?.REQUESTS_LIBRARY_BAGS,
  },
  {
    value: 'PINCODE',
    label: 'Pincode Mapping',
    permissionKey: PERMISSIONS_KEY?.MASTER_LIST_PINCODE,
  },
  {
    value: 'CHANGE_ADDRESS',
    label: 'Change Address',
    permissionKey: PERMISSIONS_KEY?.REQUESTS_END_USER_CHANGE_ADDRESS,
  },
  {
    value: 'USER_REPORT_DAMAGE',
    label: 'Report Damage',
    permissionKey: PERMISSIONS_KEY?.REQUESTS_END_USER_REPORT_DAMAGE,
  },
  {
    value: 'TOY_SUGGESTION',
    label: 'Suggested Toy',
    permissionKey: PERMISSIONS_KEY?.REQUESTS_END_USER_SUGGESTED_TOY,
  },
  {
    value: 'ORDER',
    label: 'Order',
    permissionKey: PERMISSIONS_KEY?.ORDERS_ALL_DELIVERY,
  },
  {
    value: 'INFLOW',
    label: 'Inflow',
    permissionKey: PERMISSIONS_KEY?.PAYMENTS_END_USER_INFLOW,
  },
  {
    value: 'OUTFLOW',
    label: 'Outflow',
    permissionKey: PERMISSIONS_KEY?.PAYMENTS_END_USER_OUTFLOW,
  },
  {
    value: 'RETURN_ORDER',
    label: 'Return Order',
    permissionKey: PERMISSIONS_KEY?.ORDERS_ALL_RETURN,
  },
  {
    value: 'NEW_ORDERS',
    label: 'New Orders',
    permissionKey: PERMISSIONS_KEY?.ORDERS_NEW_DELIVERY,
  },
  {
    value: 'NEW_RETURN_ORDERS',
    label: 'New Return Orders',
    permissionKey: PERMISSIONS_KEY?.ORDERS_NEW_RETURN,
  },
  {
    value: 'ORDER_CHALLANS',
    label: 'Order Challan',
    permissionKey: PERMISSIONS_KEY?.ORDERS_ALL_DELIVERY,
  },
  {
    value: 'ORDER_STICKERS',
    label: 'Order Sticker',
    permissionKey: PERMISSIONS_KEY?.ORDERS_ALL_DELIVERY,
  },
  {
    value: 'UNFULFILLED_ORDERS',
    label: 'Unfulfilled Orders',
    permissionKey: PERMISSIONS_KEY?.REPORTS_UNFULFILLED_ORDERS,
  },
  {
    value: 'HOLDING_PERIOD',
    label: 'Holding Period',
    permissionKey: PERMISSIONS_KEY?.REPORTS_HOLDING_PERIOD,
  },
  {
    value: 'REVENUE_REPORTS',
    label: 'Revenue Reports',
    permissionKey: PERMISSIONS_KEY?.REPORTS_HOLDING_PERIOD,
  },
  {
    value: 'TOY_REPORTS',
    label: 'Toy Reports',
    permissionKey: PERMISSIONS_KEY?.REPORTS_HOLDING_PERIOD,
  },
  {
    value: 'GIFT_CARD',
    label: 'Gift Card',
    permissionKey: PERMISSIONS_KEY?.OFFERS_GIFT_CARD,
  },
  {
    value: 'COUPON',
    label: 'Coupon',
    permissionKey: PERMISSIONS_KEY?.OFFERS_COUPONS,
  },
  {
    value: 'REFERRAL_TRANSACTION',
    label: 'End User Referral',
    permissionKey: PERMISSIONS_KEY?.PAYMENTS_END_USER_REFERRAL,
  },
];

export const PERMISSION_TYPE = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
};

export const NOTIFICATION_STATUS = {
  READ: 'Read',
  UNREAD: 'Unread',
};

export const COMMENT_TARGET_TYPE = {
  ORDER: 'ORDER',
  ORDER_ITEM: 'ORDER_ITEM',
};

export const COMMENT_ORDER_PHASE = {
  ORDER: 'ORDER',
  UNFULFILLED: 'UNFULFILLED',
  HOLDING_PERIOD: 'HOLDING_PERIOD',
};

export const EXPORT_TYPES_SELECT_LIBRARY = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'ORDER',
    label: 'Order',
  },
  {
    value: 'RETURN_ORDER',
    label: 'Return Order',
  },
  {
    value: 'ORDER_CHALLANS',
    label: 'Order Challan',
  },
  {
    value: 'ORDER_STICKERS',
    label: 'Order Sticker',
  },
  {
    value: 'NEW_ORDERS',
    label: 'New Orders',
  },
  {
    value: 'LIBRARY_INVENTORY',
    label: 'Library Inventory',
  },
];

export const CANCELLATION_TYPE = {
  USER: { label: 'Cancelled by User', value: 'USER' },
  ADMIN: { label: 'Cancelled by Admin', value: 'ADMIN' },
  LIBRARY: { label: 'Cancelled by Library', value: 'LIBRARY' },
  API: { label: 'Cancelled from API', value: 'API' },
  QUEUE: { label: ' Cancelled from Queue', value: 'QUEUE' },
  PAYMENT: { label: 'Cancelled because payment failed', value: 'PAYMENT' },
};

export const NUMBER_FIELD_TYPES = {
  PRICE: 'PRICE',
  CARBON_EMISSION: 'CARBON_EMISSION',
  STOCK: 'STOCK',
  AGE: 'AGE',
  PINCODE: 'PINCODE',
  DELIVERY: 'DELIVERY',
  CONFIG: 'CONFIG',
  LOCATION: 'LOCATION',
  QUANTITY: 'QUANTITY',
  TRIAL_PLAN_DAYS: 'TRIAL_PLAN_DAYS',
};

export const GIFT_CARD_STATUS = {
  ACTIVE: { value: 'ACTIVE', label: 'Active', color: 'green' },
  USED: { value: 'USED', label: 'Used', color: 'gray' },
  EXPIRED: { value: 'EXPIRED', label: 'Expired', color: 'red' },
  PAYMENT_PENDING: {
    value: 'PAYMENT_PENDING',
    label: 'Payment Pending',
    color: 'yellow',
  },
};

export const COUPON_TYPE = {
  FLAT: { value: 'FLAT', label: 'Flat' },
  PERCENT: { value: 'PERCENT', label: 'Percent' },
};

export const SUBSCRIPTION_TYPE = {
  GIFT_CARD: { label: 'Gift Card', value: 'GIFT_CARD' },
  PURCHASE: { label: 'Purchase', value: 'PURCHASE' },
  TRIAL: { label: 'Trial', value: 'TRIAL' },
  ASSIGNED: { label: 'Assigned', value: 'ASSIGNED' },
  UPDATE_SUB: { label: 'Update Subscription', value: 'UPDATE_SUB' },
  COUPON: { label: 'Coupon', value: 'COUPON' },
  ONE_TIME: { label: 'One Time', value: 'ONE_TIME' },
};

export const ACTIONS = {
  DOWNLOAD: { label: 'Download', value: 'DOWNLOAD' },
  EDIT: { label: 'Edit', value: 'EDIT' },
  MARK_AS_READ: { label: 'Mark as read', value: 'MARK_AS_READ' },
  REFERRAL_LINK: { label: 'Referral Link', value: 'REFERRAL_LINK' },
};

export const SORTING = {
  DATE_A_Z: {
    label: 'Date A-Z',
    value: 'DATE_A_Z',
    field: 'createdAt',
    sort: SORT?.ASC,
  },
  DATE_Z_A: {
    label: 'Date Z-A',
    value: 'DATE_Z_A',
    field: 'createdAt',
    sort: SORT?.DESC,
  },
  TITLE_A_Z: {
    label: 'Title A-Z',
    value: 'TITLE_A_Z',
    field: 'title',
    sort: SORT?.ASC,
  },
  TITLE_Z_A: {
    label: 'Title Z-A',
    value: 'TITLE_Z_A',
    field: 'title',
    sort: SORT?.DESC,
  },
  PRODUCT_NAME_A_Z: {
    label: 'Product Name A-Z',
    value: 'PRODUCT_NAME_A_Z',
    field: 'name',
    sort: SORT?.ASC,
  },
  PRODUCT_NAME_Z_A: {
    label: 'Product Name Z-A',
    value: 'PRODUCT_NAME_Z_A',
    field: 'name',
    sort: SORT?.DESC,
  },
  ORDER_ID_A_Z: {
    label: 'Order ID A-Z',
    value: 'ORDER_ID_A_Z',
    field: 'orderNumber',
    sort: SORT?.ASC,
  },
  ORDER_ID_Z_A: {
    label: 'Order ID Z-A',
    value: 'ORDER_ID_Z_A',
    field: 'orderNumber',
    sort: SORT?.DESC,
  },
};

export const libraryUrls = [
  'https://library.dev.theelefant.com',
  'https://library.staging.theelefant.com',
  'https://library.uat.theelefant.com',
  'https://library.theelefant.com',
  'http://localhost:3000/',
];

export const CONFIG_DATA_TYPES = {
  INTEGER: { label: 'Integer', value: 'INTEGER' },
  STRING: { label: 'String', value: 'STRING' },
};

export const CONFIG_TYPES = {
  PRIVATE: { label: 'Private', value: 'PRIVATE' },
  PUBLIC: { label: 'Public', value: 'PUBLIC' },
};

export const CUSTOM_REPORTS_KEYS = {
  REVENUE_REPORT: 'REVENUE_REPORT',
  TOY_REPORT: 'TOY_REPORT',
};

export const CUSTOM_REPORTS_DATA = [
  {
    id: 1,
    key: CUSTOM_REPORTS_KEYS?.REVENUE_REPORT,
    name: 'Revenue Report',
  },
  {
    id: 2,
    key: CUSTOM_REPORTS_KEYS?.TOY_REPORT,
    name: 'Toy Report',
  },
];

export const DEVICE_TYPE_FOR_INFLOW = {
  MOBILE: 'MOBILE',
  WEB: 'WEB',
};

export const INFLOW_PAYMENT_STATUS = {
  PAID: { text: 'Paid', color: 'green', value: 'PAID' },
  UNPAID: { text: 'Awaiting Payment', color: 'yellow', value: 'UNPAID' },
  FAILED: {
    text: 'Failed',
    color: 'red',
    value: 'FAILED',
  },
};

export const PLAN_STATUS = {
  ACTIVE: { text: 'Active', value: 'ACTIVE' },
  EXPIRED: { text: 'Expired', value: 'EXPIRED' },
  CANCELLED: { text: 'Cancelled', value: 'CANCELLED' },
  FAILED: { text: 'Failed', value: 'FAILED' },
  PENDING: { text: 'Pending', value: 'PENDING' },
  CREATED: { text: 'Created', value: 'CREATED' },
};

export const VIDEO_TYPE = {
  INTERNAL: {
    value: 'INTERNAL',
    label: 'Internal',
  },
};

export const VIDEO_STATUS = {
  DRAFT: {
    value: 'DRAFT',
    label: 'Draft',
    color: 'yellow',
  },
  PUBLISHED: {
    value: 'PUBLISHED',
    label: 'Published',
    color: 'green',
  },
};

export const PUBLISH_STATUS = {
  false: {
    value: 'false',
    label: 'Unpublished',
    color: 'red',
  },
  true: {
    value: 'true',
    label: 'Published',
    color: 'green',
  },
};

export const BRAND_STATUS = {
  PUBLISHED: {
    value: 'PUBLISHED',
    label: 'Publish',
    color: 'green',
  },
  UNPUBLISHED: {
    value: 'UNPUBLISHED',
    label: 'Unpublish',
    color: 'red',
  },
  DRAFT: {
    value: 'DRAFT',
    label: 'Draft',
    color: 'grey',
  },
};

export const UI_LABEL_TYPE = {
  HTML: {
    value: 'HTML',
    label: 'HTML',
  },
  STRING: {
    value: 'STRING',
    label: 'String',
  },
  IMAGE: {
    value: 'IMAGE',
    label: 'Image',
  },
};

export const SUBSCRIPTION_UPDATED_BY = {
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export const SUBSCRIPTION_CANCELLED_TYPES = {
  INSTANT: {
    value: 'INSTANT',
    label: 'Instant',
  },
  CYCLE_END: {
    value: 'CYCLE_END',
    label: 'Cycle End',
  },
};

export const PAYMENT_TYPE = {
  ACCOUNT: 'ACCOUNT',
  UPI: 'UPI',
};

export const REFERRAL_TYPE = {
  USER: 'USER',
  LIBRARY: 'LIBRARY',
};

export const COUPON_DURATION = {
  ONCE: {
    value: 'ONCE',
    label: 'Once',
  },
  FOREVER: {
    value: 'FOREVER',
    label: 'Forever',
  },
};

export const UI_LABEL_GROUP = {
  ONBOARDING: {
    value: 'ONBOARDING',
    label: 'Onboarding',
  },
  FAQ: {
    value: 'FAQ',
    label: 'FAQ',
  },
  SUBSCRIPTION_CANCELLATION_REASON: {
    value: 'SUBSCRIPTION_CANCELLATION_REASON',
    label: 'Subscription Cancellation Reason',
  },
};

export const DELIVERY_CONFIRMATION_TYPE = {
  USER: {
    value: 'USER',
    label: 'User',
  },
  AUTO: {
    value: 'AUTO',
    label: 'Auto',
  },
};

export const PAYMENT_PROVIDERS_TYPE = {
  PHONE_PE: {
    value: 'PHONE_PE',
    label: 'PhonePe',
  },
  STRIPE: {
    value: 'STRIPE',
    label: 'Stripe',
  },
  RAZOR_PAY: {
    value: 'RAZOR_PAY',
    label: 'Razorpay',
  },
};

export const DEVICE_TYPE = {
  MOBILE: {
    value: 'MOBILE',
    label: 'Mobile',
  },
  WEB: {
    value: 'WEB',
    label: 'Web',
  },
};
