import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { NUMBER_FIELD_TYPES } from '../../../../../common/constants';
import {
  formValidatorRules,
  getBase64,
  validateImage,
  validateVideo,
} from '../../../../../common/utils';
import CommonPreview from '../../../../../components/CommonPreview';
import NumberComponent from '../../../../../components/NumberComponent';
import AgeGroupSelect from './AgeGroupSelect';
import BrandSelect from './BrandSelect';
import CategorySelect from './CategorySelect';
import ContentMasterSelect from './ContentMasterSelect';
import FacilitatesSelect from './FacilitatesSelect';
import GenresSelect from './GenresSelect';
import MaterialSelect from './MaterialSelect';
import PackagingMaterialSelect from './PackagingMaterialSelect';
import TagsSelect from './TagsSelect';

const { Item } = Form;
const { TextArea } = Input;
const { url, price, positiveNumber, required } = formValidatorRules;

const ToyForm = ({
  form,
  onFinish,
  handleShowPrompt,
  initialFormValues,
  isView,
  toyImages,
  setToyImages,
  toyVideo,
  setToyVideo,
  toyDetail,
  featuredImage,
  setFeaturedImage,
  setIsFeaturedImageChanged,
}) => {
  const [visible, setVisible] = useState(false);
  const [toyPreviewImage, setToyPreviewImage] = useState();
  const [toyPreviewVideo, setToyPreviewVideo] = useState();
  const [inCludedContentValues, setInCludedContentValues] = useState([]);
  const [exCludedContentValues, setExCludedContentValues] = useState([]);

  const [validationTriggered, setValidationTriggered] = useState(false);

  useEffect(() => {
    setInCludedContentValues(
      toyDetail?.includedContents?.map((contentMaster) => contentMaster?.id) ||
        [],
    );
    setExCludedContentValues(
      toyDetail?.excludedContents?.map((contentMaster) => contentMaster?.id) ||
        [],
    );
    const initialValueFun = () => {
      form?.setFieldsValue(initialFormValues);
    };
    setTimeout(initialValueFun, 300);
  }, []);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleChange = (info) => {
    validateImage(info, form, setToyImages, 'images');
  };

  const handleVideoChange = async (info) => {
    const res = await validateVideo(info, form, setToyVideo, 'video', true);
    if (res) {
      setToyPreviewVideo(
        URL.createObjectURL(info?.fileList?.[0]?.originFileObj),
      );
    }
  };

  const handleFeaturedImageChange = async (info) => {
    const isValidImage = await validateImage(
      info,
      form,
      setFeaturedImage,
      'featuredImages',
    );
    if (isValidImage && initialFormValues) {
      setIsFeaturedImageChanged(true);
    }
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setToyPreviewImage(fileObj?.url || preview);
  };

  const handleIncludedContent = (value, fieldIndex) => {
    const newSelectedValues = [...inCludedContentValues];
    newSelectedValues[fieldIndex] = value;
    setInCludedContentValues(newSelectedValues);
  };

  const handleExcludedContent = (value, fieldIndex) => {
    const newSelectedValues = [...exCludedContentValues];
    newSelectedValues[fieldIndex] = value;
    setExCludedContentValues(newSelectedValues);
  };

  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={toyPreviewImage}
        setImage={setToyPreviewImage}
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={handleShowPrompt}
        disabled={!!isView || toyDetail?.isArchived}
        onFinishFailed={onFinishFailed}
        className={isView ? 'common-view-form' : 'form'}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
            <Item
              label="Product ID"
              name="id"
              rules={[{ ...required, message: 'Please Enter Product ID!' }]}
            >
              <Input allowClear placeholder="Enter Product ID" maxLength={50} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
            <Item
              label="Product Name"
              name="name"
              rules={[{ ...required, message: 'Please Enter Product Name!' }]}
            >
              <Input
                allowClear
                placeholder="Enter Product Name"
                maxLength={200}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
            <Item
              label="Product Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Product Price!',
                },
                price,
              ]}
            >
              <NumberComponent
                allowClear
                placeholder="Enter Product Price"
                type={NUMBER_FIELD_TYPES?.PRICE}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Item label="EAN Number" name="eanNumber" rules={[positiveNumber]}>
              <NumberComponent allowClear placeholder="Enter EAN Number" />
            </Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Item label="Product Dimensions" name="dimensions">
              <Input
                allowClear
                placeholder="Enter Product Dimensions"
                maxLength={200}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Item label="Product URL" name="url" rules={[url]}>
              <Input allowClear placeholder="Enter Product URL" />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
            <Item
              label="Total Carbon Emission (kg)"
              name="carbonEmission"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Product Carbon Emission!',
                },
                price,
              ]}
            >
              <NumberComponent
                allowClear
                placeholder="Enter Product Carbon Emission"
                type={NUMBER_FIELD_TYPES?.CARBON_EMISSION}
              />
            </Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item
              label="Short Description"
              name="shortDescription"
              rules={[
                {
                  ...required,
                  message: 'Please Enter Product Short Description!',
                },
              ]}
            >
              <TextArea
                rows={2}
                allowClear
                placeholder="Enter Product Description"
                showCount
                maxLength={400}
              />
            </Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item
              label="Description"
              name="description"
              rules={[
                {
                  ...required,
                  message: 'Please Enter Product Description!',
                },
              ]}
            >
              <TextArea
                rows={4}
                allowClear
                placeholder="Enter Product Description"
                showCount
                maxLength={1200}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Categories"
              name="category"
              rules={[
                {
                  required: true,
                  message: 'Please Select Product Categories!',
                },
              ]}
            >
              <CategorySelect toyDetail={toyDetail} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Age Groups"
              name="ageGroup"
              rules={[{ required: true, message: 'Please Select Age Groups!' }]}
            >
              <AgeGroupSelect toyDetail={toyDetail} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Brand"
              name="brand"
              rules={[{ required: true, message: 'Please Select Brand!' }]}
            >
              <BrandSelect toyDetail={toyDetail} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Material"
              name="material"
              rules={[{ required: true, message: 'Please Select Material!' }]}
            >
              <MaterialSelect toyDetail={toyDetail} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item label="Facilitates" name="facilitates">
              <FacilitatesSelect toyDetail={toyDetail} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item label="Tags" name="tags">
              <TagsSelect toyDetail={toyDetail} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item label="Genres" name="genres">
              <GenresSelect toyDetail={toyDetail} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Packaging Material"
              name="packagingMaterial"
              rules={[
                {
                  required: true,
                  message: 'Please Select Packaging Material!',
                },
              ]}
            >
              <PackagingMaterialSelect toyDetail={toyDetail} />
            </Item>
          </Col>
          <Col xs={24} sm={12} className="content-master-form-list">
            <Item label="Included Content Master">
              <Form.List name="includedContentMasters">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Space
                        key={key}
                        align="baseline"
                        className="content-master-wrapper"
                      >
                        <Space.Compact>
                          <Form.Item
                            {...restField}
                            name={[name, 'id']}
                            rules={[
                              {
                                ...required,
                                message: 'Please Select Content Master!',
                              },
                            ]}
                          >
                            <ContentMasterSelect
                              form={form}
                              toyDetail={toyDetail}
                              index={index}
                              contentMasters={toyDetail?.includedContents}
                              inCludedContentValues={inCludedContentValues}
                              onChange={(value) =>
                                handleIncludedContent(value, index)
                              }
                              includedContentsSelect
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'quantity']}
                            rules={[
                              {
                                required: true,
                                message: 'Please Enter Quantity!',
                              },
                            ]}
                            getValueFromEvent={(event) => {
                              const { value } = event.target;
                              const parsedValue = parseInt(value, 10);
                              return Number.isNaN(parsedValue)
                                ? 0
                                : parsedValue;
                            }}
                          >
                            <NumberComponent
                              allowClear
                              isFractional={false}
                              placeholder="Enter Quantity"
                              type={NUMBER_FIELD_TYPES?.QUANTITY}
                            />
                          </Form.Item>
                        </Space.Compact>
                        <Button type="link" disabled={isView}>
                          <CloseOutlined onClick={() => remove(name)} />
                        </Button>
                      </Space>
                    ))}
                    {fields.length < 20 && (
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Content Master
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.List>
            </Item>
          </Col>
          <Col xs={24} sm={12} className="content-master-form-list">
            <Item label="Excluded Content Master">
              <Form.List name="excludedContentMasters">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Space
                        key={key}
                        align="baseline"
                        className="content-master-wrapper"
                      >
                        <Space.Compact>
                          <Form.Item
                            {...restField}
                            name={[name, 'id']}
                            rules={[
                              {
                                ...required,
                                message: 'Please Select Content Master!',
                              },
                            ]}
                          >
                            <ContentMasterSelect
                              form={form}
                              toyDetail={toyDetail}
                              contentMasters={toyDetail?.excludedContents}
                              index={index}
                              exCludedContentValues={exCludedContentValues}
                              onChange={(value) =>
                                handleExcludedContent(value, index)
                              }
                              excludedContentsSelect
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'quantity']}
                            rules={[
                              {
                                required: true,
                                message: 'Please Enter Quantity!',
                              },
                            ]}
                            getValueFromEvent={(event) => {
                              const { value } = event.target;
                              const parsedValue = parseInt(value, 10);
                              return Number.isNaN(parsedValue)
                                ? 0
                                : parsedValue;
                            }}
                          >
                            <NumberComponent
                              allowClear
                              isFractional={false}
                              placeholder="Enter Quantity"
                              type={NUMBER_FIELD_TYPES?.QUANTITY}
                            />
                          </Form.Item>
                        </Space.Compact>
                        <Button type="link" disabled={isView}>
                          <CloseOutlined onClick={() => remove(name)} />
                        </Button>
                      </Space>
                    ))}
                    {fields.length < 20 && (
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Content Master
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.List>
            </Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item
              label="Featured Image"
              name="featuredImages"
              rules={[
                { required: true, message: 'Please Upload Featured Image!' },
              ]}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                maxCount={1}
                fileList={featuredImage}
                beforeUpload={() => false}
                onPreview={handlePreview}
                onChange={handleFeaturedImageChange}
                className="avatar-uploader"
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: true,
                  showRemoveIcon: true,
                }}
              >
                {!isView && featuredImage?.length !== 1 && <PlusOutlined />}
              </Upload>
            </Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item
              label="Images"
              name="images"
              rules={[{ required: true, message: 'Please Upload Images!' }]}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                maxCount={5}
                fileList={toyImages}
                beforeUpload={() => false}
                onPreview={handlePreview}
                onChange={handleChange}
                className="avatar-uploader"
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: true,
                  showRemoveIcon: true,
                }}
              >
                {!isView && toyImages?.length !== 5 && <PlusOutlined />}
              </Upload>
            </Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item label="Video" name="video">
              {!toyPreviewVideo && !toyVideo?.url ? (
                <Upload
                  listType="picture-card"
                  maxCount={1}
                  fileList={toyVideo}
                  beforeUpload={() => false}
                  onChange={handleVideoChange}
                  showUploadList={{
                    showDownloadIcon: false,
                    showPreviewIcon: false,
                    showRemoveIcon: false,
                  }}
                >
                  {toyVideo?.length !== 1 && <PlusOutlined />}
                </Upload>
              ) : (
                <>
                  <div className="toy-video-container">
                    <video
                      width="100%"
                      height="100%"
                      controls
                      src={toyPreviewVideo || toyVideo?.url}
                      className="toy-video"
                    >
                      <track kind="captions" />
                    </video>
                    {!isView && (
                      <DeleteOutlined
                        className="toy-video-delete-icon"
                        onClick={() => {
                          setToyVideo([]);
                          setToyPreviewVideo();
                          handleShowPrompt();
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ToyForm;
