import { Card, Dropdown, Form, Image, Tag } from 'antd';
import { map } from 'lodash';
import React, { useState } from 'react';
import { ReactComponent as CommentIcon } from '../../../assets/svg/comment-dots-solid.svg';
import { ReactComponent as MoreIcon } from '../../../assets/svg/ellipsis-vertical-solid.svg';
import {
  DAMAGE_TABLE_ACTIONS_OPTIONS,
  DATE_FORMATS,
  REQUEST_STATUS,
} from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import ViewCommentsModal from './ViewCommentsModal';

const DamageCard = ({
  damage,
  infiniteScrollRef,
  setVisible,
  setToyImages,
  setDamage,
  setIsCommentPrompts,
  setIsContentMasterModalOpen,
}) => {
  const [form] = Form.useForm();
  const [isViewCommentPrompts, setIsViewCommentPrompts] = useState(false);
  const [isPendingComment, setIsPendingComment] = useState(false);
  const handleMenuClick = (e) => {
    setDamage(damage);
    if (e?.key === DAMAGE_TABLE_ACTIONS_OPTIONS?.ACCEPT?.value) {
      setIsCommentPrompts(true);
    } else {
      setIsContentMasterModalOpen(true);
    }
  };

  const menuProps = {
    items: map(DAMAGE_TABLE_ACTIONS_OPTIONS, (item) =>
      item?.value === DAMAGE_TABLE_ACTIONS_OPTIONS?.ACCEPT?.value
        ? {
            label: item.label,
            key: item.value,
            disabled: [
              REQUEST_STATUS?.PENDING?.value,
              REQUEST_STATUS?.APPROVED?.value,
              REQUEST_STATUS?.REJECTED?.value,
            ]?.includes(damage?.status),
          }
        : {
            label: item.label,
            key: item.value,
          },
    ),
    onClick: handleMenuClick,
  };

  return (
    <>
      <Card
        className="ml-12 mr-12 mb-24 mt-12 mobile-card"
        title={
          <div className="overflow-scroll no-scroll-bar">
            {damage?.requestNumber}
          </div>
        }
        extra={
          <div className="d-flex align-center">
            <span className="mr-16 ml-16">
              {damage?.images?.length > 0 && (
                <>
                  <Image
                    preview={{
                      visible: false,
                    }}
                    width={20}
                    height={20}
                    src={damage?.images?.[0]?.url}
                    onClick={(e) => {
                      e?.stopPropagation();
                      setVisible(true);
                      setToyImages(damage?.images);
                    }}
                    className="pointer"
                  />
                </>
              )}
              {damage?.images?.length > 1
                ? ` + ${damage?.images?.length - 1}`
                : ''}
            </span>
            <Tag
              color={REQUEST_STATUS?.[damage?.status]?.color}
              className="mr-16"
            >
              {REQUEST_STATUS?.[damage?.status]?.text}
            </Tag>
            <Dropdown
              getPopupContainer={(trigger) => trigger?.parentNode}
              menu={menuProps}
            >
              <MoreIcon
                className="pointer mobile-svg-icon"
                onClick={(e) => {
                  e?.stopPropagation();
                }}
              />
            </Dropdown>
          </div>
        }
        ref={infiniteScrollRef}
      >
        <div className="mb-8">
          <span className="mobile-card-label">User ID: </span>
          <span>{damage?.createdBy?.userNumber}</span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Delivery Partner Name: </span>
          <span>{`${damage?.returnOrderItemDetails?.[0]?.returnOrder?.deliveryPartner?.firstName} ${damage?.returnOrderItemDetails?.[0]?.returnOrder?.deliveryPartner?.lastName}`}</span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Product Name: </span>
          <span>
            {damage?.returnOrderItemDetails?.[0]?.libraryInventory?.toy?.name}
          </span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Request Date: </span>
          <span>{formatDate(damage?.createdAt, DATE_FORMATS?.REGULAR)}</span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Damage Description: </span>
          <span>{damage?.description || 'No Description'}</span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-labe d-flex align-center">
            Pending Comment:{' '}
          </span>
          <span>
            {(damage?.metaData?.pendingComment && (
              <CommentIcon
                className="mr-16 pointer svg-icon"
                onClick={() => {
                  form?.setFieldsValue({
                    comment: damage?.metaData?.pendingComment?.comment,
                    reason: damage?.metaData?.pendingComment?.reason,
                  });
                  setIsPendingComment(true);
                  setIsViewCommentPrompts(true);
                }}
              />
            )) ||
              '-'}
          </span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Resolving Comment: </span>
          <span>
            {((damage?.metaData?.resolveComment ||
              damage?.metaData?.comment) && (
              <CommentIcon
                className="mr-16 pointer svg-icon"
                onClick={() => {
                  form?.setFieldsValue({
                    comment:
                      damage?.metaData?.resolveComment?.comment ||
                      damage?.metaData?.comment,
                    reason: damage?.metaData?.resolveComment?.reason,
                  });
                  setIsPendingComment(false);
                  setIsViewCommentPrompts(true);
                }}
              />
            )) ||
              '-'}
          </span>
        </div>
      </Card>
      <ViewCommentsModal
        form={form}
        isModalOpen={isViewCommentPrompts}
        setIsModalOpen={setIsViewCommentPrompts}
        libraryComments={damage?.metaData?.actionsByLibrary}
        status={damage?.status}
        metadata={damage?.metaData}
        damage={damage}
        isPendingComment={isPendingComment}
        isLibrarian
      />
    </>
  );
};

export default DamageCard;
