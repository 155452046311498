import { Col, DatePicker, Divider, Form, Input, Row, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  DATE_FORMATS,
  NUMBER_FIELD_TYPES,
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import NumberComponent from '../../../../../components/NumberComponent';
import SubscriptionPlanSelect from '../../../../offers/modules/giftCard/components/SubscriptionPlanSelect';

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;
const { whitespaceNotAllowed } = formValidatorRules;

const SubscriptionModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
}) => {
  const [form] = Form.useForm();
  const [cardPrice, setCardPrice] = useState(0);
  const [planMembership, setPlanMembership] = useState(0);
  const [planSecurityDeposit, setPlanSecurityDeposit] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [cardPricePaid, setCardPricePaid] = useState(0);
  const [securityDepositPaid, setsecurityDepositPaid] = useState(0);
  const [membershipFeesPaid, setmembershipFeesPaid] = useState(0);
  const [PayableAmount, setPayableAmount] = useState(0);
  const [foc, setFoc] = useState('');
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);
  const [showTransactionId, setShowTransactionId] = useState(false);
  const defaultValue = moment();
  // eslint-disable-next-line no-console
  console.log(foc);
  let planprices;
  let discountPrices = 0;
  const disabledDate = (current) => current && current > moment().endOf('day');
  const handleCancel = () => {
    setIsModalOpen(false);
    setFoc('');
    setCardPrice(0);
    setPlanMembership(0);
    setPlanSecurityDeposit(0);
    setCardPricePaid(0);
    setmembershipFeesPaid(0);
    setsecurityDepositPaid(0);
    setDiscountedPrice(0);
    setPayableAmount(0);
    setShowTransactionId(false);
    form?.resetFields();
  };
  const handleFOC = (value) => {
    setFoc(value);
  };
  const handleCardPricePaid = (e) => {
    setCardPricePaid(e.target.value);
  };
  const handlesecurityDepositPaid = (e) => {
    setsecurityDepositPaid(e.target.value);
  };
  const handlemembershipChargesPaid = (e) => {
    setmembershipFeesPaid(e.target.value);
  };
  const handleModeOfPaymentChange = (value) => {
    setShowTransactionId(value !== 'cash');
  };
  const validateCardPricePaid = (_, value) =>
    new Promise((resolve, reject) => {
      if (value === null || value === undefined || value === '') {
        reject(new Error('Card price is required'));
        return;
      }
      if (parseFloat(cardPrice) === 0 && parseFloat(value) > 0) {
        reject(new Error(`Card price must be ${cardPrice}`));
      } else if (value && parseFloat(value) > cardPrice) {
        reject(
          new Error(`Card price must be less than or equal to  ${cardPrice}`),
        );
      } else {
        resolve();
      }
    });
  const validatemembershipFeesPaid = (_, value) =>
    new Promise((resolve, reject) => {
      if (value === null || value === undefined || value === '') {
        reject(new Error('`Membership Charges is required'));
        return;
      }
      if (value && parseFloat(planMembership) === 0 && parseFloat(value) > 0) {
        reject(new Error(`Membership Charges must be ${planMembership}`));
      } else if (value && parseFloat(value) > planMembership) {
        reject(
          new Error(
            `Membership Charges must be less than or equal to ${planMembership}`,
          ),
        );
      } else {
        resolve();
      }
    });

  const validatesecurityDepositPaid = (_, value) =>
    new Promise((resolve, reject) => {
      if (value === null || value === undefined || value === '') {
        reject(new Error('`Security deposit is required'));
        return;
      }
      if (parseFloat(planSecurityDeposit) === 0 && parseFloat(value) > 0) {
        reject(new Error(`Security deposit must be ${planSecurityDeposit}`));
      } else if (value && parseFloat(value) > planSecurityDeposit) {
        reject(
          new Error(
            `Security deposit must be less than or equal to  ${planSecurityDeposit}`,
          ),
        );
      } else {
        resolve();
      }
    });
  const submitForm = () =>
    new Promise(() => {
      form.submit();
    });

  const submitActiveSubscription = async () => {
    await submitForm();
    setFoc('');
    setCardPrice(0);
    setPlanMembership(0);
    setPlanSecurityDeposit(0);
    setCardPricePaid(0);
    setmembershipFeesPaid(0);
    setsecurityDepositPaid(0);
    setPayableAmount(0);
    setDiscountedPrice(0);
  };
  useEffect(() => {
    // calculate discount price
    discountPrices =
      parseInt(cardPrice || 0, 10) -
      parseInt(cardPricePaid || 0, 10) +
      (parseInt(planMembership || 0, 10) -
        parseInt(membershipFeesPaid || 0, 10)) +
      (parseInt(planSecurityDeposit || 0, 10) -
        parseInt(securityDepositPaid || 0, 10));
    if (form.getFieldValue().paid === 'foc') {
      setPayableAmount(0);
      setCardPricePaid(0);
      setmembershipFeesPaid(0);
      setsecurityDepositPaid(0);
      setTimeout(() => {
        form?.setFieldsValue({
          modeOfPayment: 'other',
          transactionId: 'NA',
          discount: 0,
          payableAmount: 0,
          pricePaid: 0,
          securityDepositPaid: 0,
          membershipChargesPaid: 0,
          activatedAt: defaultValue,
        });
      }, 300);
    }

    const initialValueFun = () => {
      form?.setFieldsValue({
        price: cardPrice,
        membershipCharges: planMembership,
        securityDeposit: planSecurityDeposit,
        paid: foc,
        totalDiscount: discountedPrice,
        payableAmount: PayableAmount,
      });
    };
    setDiscountedPrice(discountPrices);
    setPayableAmount(
      parseInt(cardPricePaid || 0, 10) +
        parseInt(membershipFeesPaid || 0, 10) +
        parseInt(securityDepositPaid || 0, 10),
    );
    setTimeout(initialValueFun, 300);
  }, [
    cardPrice,
    discountPrices,
    planprices,
    cardPricePaid,
    membershipFeesPaid,
    securityDepositPaid,
    PayableAmount,
    planMembership,
    planSecurityDeposit,
    discountedPrice,
    form.getFieldValue(),
  ]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={submitActiveSubscription}
        title="Activate Subscription"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
            onFinishFailed={() => setIsValidateTrigger(true)}
          >
            <Item
              label="Plan"
              name="plan"
              rules={[{ required: true, message: 'Please Select Plan!' }]}
            >
              <SubscriptionPlanSelect
                onChange={(_, option) => {
                  setCardPrice(option?.price);
                  setPlanMembership(
                    option?.data?.subscriptionFeatures?.memberShipFees,
                  );
                  setPlanSecurityDeposit(
                    option?.data?.subscriptionFeatures?.refundableDeposit,
                  );
                }}
              />
            </Item>
            <Item
              label="FOC / Paid"
              name="paid"
              rules={[{ required: true, message: 'Please select FOC/Paid' }]}
            >
              <Select
                placeholder="please select"
                allowClear
                onChange={handleFOC}
              >
                <Option value="foc">FOC</Option>
                <Option value="paid">Paid</Option>
              </Select>
            </Item>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <Item label="Plan Price" name="price">
                  <Input placeholder="Plan Price" value={cardPrice} disabled />
                </Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <Item label="Security deposit" name="securityDeposit">
                  <NumberComponent
                    allowClear
                    placeholder="Enter Security deposit"
                    type={NUMBER_FIELD_TYPES?.PRICE}
                    disabled
                  />
                </Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <Item label="Membership Charges" name="membershipCharges">
                  <NumberComponent
                    allowClear
                    placeholder="Enter Membership Charges"
                    type={NUMBER_FIELD_TYPES?.PRICE}
                    disabled
                  />
                </Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <Item
                  label="Plan Price"
                  name="pricePaid"
                  rules={[{ validator: validateCardPricePaid }]}
                >
                  <Input
                    onChange={handleCardPricePaid}
                    placeholder="Plan Price"
                  />
                </Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <Item
                  label="Security deposit"
                  name="securityDepositPaid"
                  rules={[{ validator: validatesecurityDepositPaid }]}
                >
                  <NumberComponent
                    allowClear
                    placeholder="Enter Security deposit"
                    type={NUMBER_FIELD_TYPES?.PRICE}
                    onChange={handlesecurityDepositPaid}
                  />
                </Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <Item
                  label="Membership Charges"
                  name="membershipChargesPaid"
                  rules={[{ validator: validatemembershipFeesPaid }]}
                >
                  <NumberComponent
                    allowClear
                    placeholder="Enter Membership Charges"
                    type={NUMBER_FIELD_TYPES?.PRICE}
                    onChange={handlemembershipChargesPaid}
                  />
                </Item>
              </Col>
            </Row>
            <Item label="Payable amount" name="payableAmount">
              <NumberComponent
                allowClear
                placeholder="Enter Amount"
                type={NUMBER_FIELD_TYPES?.PRICE}
                value={form?.getFieldValue().paid === 'foc' ? 0 : PayableAmount}
                disabled
              />
            </Item>
            <Item label="Total Discount" name="totalDiscount">
              <Input
                placeholder="Total Discount Price"
                value={discountedPrice}
                disabled
              />
            </Item>
            <Item
              label="Mode of Payment"
              name="modeOfPayment"
              rules={[
                { required: true, message: 'Please select Mode of Payment!' },
              ]}
            >
              <Select
                placeholder="Select Mode of Payment"
                allowClear
                onChange={handleModeOfPaymentChange}
              >
                <Option value="stripe">Stripe</Option>
                <Option value="razorpay">Razorpay</Option>
                <Option value="phonepe">PhonePe</Option>
                <Option value="upi">UPI</Option>
                <Option value="pos">POS</Option>
                <Option value="cash">Cash</Option>
                <Option value="other">Other</Option>
              </Select>
            </Item>

            {showTransactionId && (
              <Item
                label="Transaction ID"
                name="transactionId"
                rules={[
                  { required: true, message: 'Please Enter Transaction ID!' },
                  {
                    pattern: /^[A-Za-z0-9]+$/,
                    message: 'Only alphanumeric characters are allowed',
                  },
                ]}
              >
                <Input placeholder="Enter Transaction ID" />
              </Item>
            )}
            <Item
              label="Payment Date"
              name="activatedAt"
              rules={[
                { required: true, message: 'Please Enter Payment Date!' },
              ]}
            >
              <DatePicker
                format={DATE_FORMATS?.REGULAR}
                placeholder="Select Payment Date"
                className="subscription-date-picker"
                popupClassName="picker-with-scroll"
                disabledDate={disabledDate}
              />
            </Item>
            <Item name="note" label="Note" rules={[whitespaceNotAllowed]}>
              <TextArea
                rows={3}
                allowClear
                placeholder="Enter Note"
                showCount
                maxLength={700}
              />
            </Item>
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};

export default SubscriptionModal;
