import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const BAGS_REQUESTS_ADMIN = gql`
  query RequestsAdmin($filter: RequestsAdminFilterInput, $sort: RequestsSort) {
    requestsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        createdAt
        metaData {
          ... on BagRequest {
            quantity
            size
            name
            image
            type
          }
        }
        library {
          id
          libraryNumber
          name
        }
        status
        isArchived
        requestNumber
      }
    }
  }
`;

export const NEW_TOYS_REQUESTS_ADMIN = gql`
  query RequestsAdmin($filter: RequestsAdminFilterInput, $sort: RequestsSort) {
    requestsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        library {
          id
          libraryNumber
          name
        }
        id
        createdAt
        metaData {
          ... on NewToy {
            brand
            productName
            url
          }
        }
        isArchived
        status
        requestNumber
      }
    }
  }
`;

export const CLOSURE_REQUESTS_ADMIN = gql`
  query RequestsAdmin($sort: RequestsSort, $filter: RequestsAdminFilterInput) {
    requestsAdmin(sort: $sort, filter: $filter) {
      count
      data {
        id
        requestNumber
        status
        createdAt
        library {
          name
          libraryNumber
          id
          isActive
          backUpLibrary {
            id
            name
            libraryNumber
            isActive
          }
        }
        metaData {
          ... on ClosureRequest {
            fromDate
            toDate
            isProcessed
            isReActivateBackUp
          }
        }
        description
        updatedAt
        isArchived
      }
    }
  }
`;

export const LIBRARIES = gql`
  query Libraries($filter: LibraryFilter, $sort: LibrarySort) {
    libraries(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const TOYS = gql`
  query Toys($filter: ToysFilter, $sort: ToysSort) {
    toys(filter: $filter, sort: $sort) {
      count
      data {
        name
        id
      }
    }
  }
`;

export const LIBRARY_DAMAGE_REQUESTS_ADMIN = gql`
  query RequestsAdmin($filter: RequestsAdminFilterInput, $sort: RequestsSort) {
    requestsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        videoUrl
        id
        library {
          id
          libraryNumber
          name
        }
        damager {
          firstName
          lastName
          userNumber
          id
        }
        createdBy {
          id
          userNumber
          firstName
          lastName
        }
        requestNumber
        returnOrderItemDetails {
          id
          contentHistory {
            id
            newContent {
              id
              quantity
              value
            }
            oldContent {
              id
              quantity
              value
            }
            status
            createdAt
          }
          returnOrder {
            id
            deliveryPartner {
              firstName
              lastName
              id
              userNumber
            }
          }
          libraryInventory {
            id
            toy {
              name
              id
              toyNumber
            }
          }
        }
        images {
          key
          url
        }
        metaData {
          ... on LibraryDamage {
            comment
            resolveComment {
              reason
              comment
              updatedAt
            }
            pendingComment {
              reason
              comment
              updatedAt
            }
            returnOrderItemId
            actionsByLibrary {
              comment
              updatedOn
            }
          }
        }
        createdAt
        description
        status
        isArchived
      }
    }
  }
`;

export const LIBRARY_ENCASHMENT_REQUESTS_ADMIN = gql`
  query RequestsAdmin($filter: RequestsAdminFilterInput, $sort: RequestsSort) {
    requestsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        isArchived
        metaData {
          ... on EncashmentRequest {
            amount
            comment
          }
        }
        createdAt
        status
        requestNumber
        createdBy {
          deletedAt
          firstName
          lastName
          id
          userNumber
          bankAccounts {
            accountNumber
            createdAt
            holderName
            id
            ifsc
            isDefault
            upiId
            type
          }
        }
      }
    }
  }
`;
