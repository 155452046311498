import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Image,
  Modal,
  Radio,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as InfoIcon } from '../../../../../assets/svg/info-solid.svg';
import { ReactComponent as PlayIcon } from '../../../../../assets/svg/play-solid.svg';
import { ReactComponent as DislikeRatingIcon } from '../../../../../assets/svg/thumbs-down-solid.svg';
import { ReactComponent as LikeRatingIcon } from '../../../../../assets/svg/thumbs-up-solid.svg';
import {
  DATE_FORMATS,
  DELIVERY_TYPE,
  DELIVERY_TYPE_OPTIONS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  PRODUCT_RATE,
  RETURN_ORDER_ITEM_STATUS,
  ROUTES,
  SORT,
  USER_ROLES,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonPreview from '../../../../../components/CommonPreview';
import CommonPreviewTable from '../../../../../components/CommonPreviewTable';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { PINCODES } from '../../../../../components/graphql/Queries';
import ToyVideoModal from '../../../../../libraryModules/raiseRequest/components/ToyVideoModal';
import { USERS } from '../../../../../libraryModules/raiseRequest/graphql/Queries';
import { LIBRARIES } from '../../../../lists/modules/library/graphql/Queries';
import { CANCEL_RETURN_ORDER_REQUEST_ADMIN } from '../graphql/Mutations';
import {
  RETURN_ORDERS_ADMIN,
  RETURN_ORDERS_ADMIN_SELECT,
  TOYS,
} from '../graphql/Queries';
import ContentMasterModal from './ContentMasterModal';
import ReturnItemStatusModal from './ReturnItemStatusModal';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialOrderFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialOrderSort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const AllReturnTable = ({ exportFilter, setExportFilter }) => {
  const {
    state: {
      permissions,
      commonPermissions: {
        isUserPermission,
        isDeliveryPartnerPermission,
        isToyPermission,
        isLibraryPermission,
      },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [orderList, setOrderList] = useState([]);
  const [orderSearchTerm, setOrderSearchTerm] = useState('');
  const [isEmptyOrderList, setIsEmptyOrderList] = useState(false);
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [order, setOrder] = useState({});
  const [isOrderLoading, setIsOrderLoading] = useState(true);
  const [isRequestPrompts, setIsRequestPrompts] = useState(false);
  const [isRequestLoadings, setIsRequestLoadings] = useState(false);
  const [orderItem, setOrderItem] = useState({});

  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [toyPreviewImage, setToyPreviewImage] = useState();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);
  const [isOrderStatusModalOpen, setIsOrderStatusModalOpen] = useState(false);
  const [isContentMasterModalOpen, setIsContentMasterModalOpen] = useState(
    false,
  );

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.ORDERS_ALL_RETURN,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  const [returnOrdersAdmin] = useLazyQuery(RETURN_ORDERS_ADMIN, {
    onCompleted: (response) => {
      setOrderList([...response?.returnOrdersAdmin?.data]);
      if (
        response?.returnOrdersAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyOrderList(true);
      } else {
        setIsEmptyOrderList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.returnOrdersAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsOrderLoading(false);
    },
    fetchPolicy: 'no-cache',
    onError() {},
  });

  const [pincodes, { loading: isPincodesLoading }] = useLazyQuery(PINCODES, {
    onCompleted: (response) => {
      if (response?.pincodes?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.pincodes?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.pincodes?.data, (item) =>
        optionsCopy?.push({
          label: item?.pincode,
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [users, { loading: isUsersLoading }] = useLazyQuery(USERS, {
    onCompleted: (response) => {
      if (response?.users?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.users?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.users?.data, (item) =>
        optionsCopy?.push({
          label: `${
            item?.firstName || item?.lastName
              ? `${item?.firstName || ''} ${item?.lastName || ''}`
              : '-'
          }`,
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [
    returnOrdersAdminSelect,
    { loading: isReturnOrdersAdminSelectLoading },
  ] = useLazyQuery(RETURN_ORDERS_ADMIN_SELECT, {
    onCompleted: (response) => {
      if (response?.returnOrdersAdmin?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.returnOrdersAdmin?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.returnOrdersAdmin?.data, (item) =>
        optionsCopy?.push({
          label: item?.returnOrder?.returnOrderNumber,
          key: item?.returnOrder?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [toys, { loading: isToysLoading }] = useLazyQuery(TOYS, {
    onCompleted: (response) => {
      if (response?.toys?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.toys?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.toys?.data, (item) =>
        optionsCopy?.push({
          label: item?.name,
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [libraries, { loading: isLibrariesLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [cancelReturnOrderRequestAdmin] = useMutation(
    CANCEL_RETURN_ORDER_REQUEST_ADMIN,
    {
      onError: () => {},
    },
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    returnOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
        },
        sort: initialOrderSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsOrderLoading(true);
    setSortedInfo(sorter);
    returnOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          skip,
          limit: pagination?.pageSize,
          search: orderSearchTerm,
          deliveryType: finalWhereFilters?.deliveryType,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          returnedOn: {
            from: finalWhereFilters?.returnedOn?.from?.$d,
            to: finalWhereFilters?.returnedOn?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          pincodeIds: finalWhereFilters?.pincodes,
          deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
          returnOrderIds: finalWhereFilters?.returnOrderNumber,
          toyIds: finalWhereFilters?.productName,
          libraryIds: finalWhereFilters?.name,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setOrderSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    returnOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          deliveryType: finalWhereFilters?.deliveryType,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          returnedOn: {
            from: finalWhereFilters?.returnedOn?.from?.$d,
            to: finalWhereFilters?.returnedOn?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          pincodeIds: finalWhereFilters?.pincodes,
          deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
          returnOrderIds: finalWhereFilters?.returnOrderNumber,
          toyIds: finalWhereFilters?.productName,
          libraryIds: finalWhereFilters?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleReturnRequest = async () => {
    setIsRequestLoadings(true);
    const response = await cancelReturnOrderRequestAdmin({
      variables: {
        where: {
          id: order?.id,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsRequestPrompts(false);
      returnOrdersAdmin({
        variables: {
          filter: {
            ...initialOrderFilter,
            limit: paginationProp?.pageSize || LIMIT,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            returnedOn: {
              from: finalWhereFilters?.returnedOn?.from?.$d,
              to: finalWhereFilters?.returnedOn?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            pincodeIds: finalWhereFilters?.pincodes,
            deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
            returnOrderIds: finalWhereFilters?.returnOrderNumber,
            toyIds: finalWhereFilters?.productName,
            libraryIds: finalWhereFilters?.name,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialOrderSort,
        },
      });
    }
    setIsRequestLoadings(false);
  };

  const getFilterData = (confirm) => {
    setIsOrderLoading(true);
    returnOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          deliveryType: whereFilter?.deliveryType,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          returnedOn: {
            from: whereFilter?.returnedOn?.from?.$d,
            to: whereFilter?.returnedOn?.to?.$d,
          },
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
          pincodeIds: whereFilter?.pincodes,
          deliveryPartnerIds: whereFilter?.deliveryPartnerName,
          returnOrderIds: whereFilter?.returnOrderNumber,
          toyIds: whereFilter?.productName,
          libraryIds: whereFilter?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'deliveryType') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsOrderLoading(true);
    returnOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          deliveryType: whereFiltersCopy?.deliveryType,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          returnedOn: {
            from: whereFiltersCopy?.returnedOn?.from?.$d,
            to: whereFiltersCopy?.returnedOn?.to?.$d,
          },
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
          pincodeIds: whereFiltersCopy?.pincodes,
          deliveryPartnerIds: whereFiltersCopy?.deliveryPartnerName,
          returnOrderIds: whereFiltersCopy?.returnOrderNumber,
          toyIds: whereFiltersCopy?.productName,
          libraryIds: whereFiltersCopy?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      const optionsCopy = [];

      switch (filterIndex) {
        case 'returnOrderNumber':
          returnOrdersAdminSelect({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'deliveryPartnerName':
          users({
            variables: {
              filter: {
                ...initialTableFilter,
                roles: [USER_ROLES?.DELIVERY?.key],
              },
              sort: initialTableSort,
            },
          });
          break;

        case 'productName':
          toys({
            variables: {
              filter: {
                ...initialTableFilter,
              },
              sort: initialTableSort,
            },
          });
          break;

        case 'pincodes':
          pincodes({
            variables: {
              filter: {
                ...initialTableFilter,
              },
              sort: {
                field: 'pincode',
                order: 'ASC',
              },
            },
          });
          break;

        case 'deliveryType':
          forEach(DELIVERY_TYPE_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'status':
          forEach(RETURN_ORDER_ITEM_STATUS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'name':
          libraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (dataIndex === 'status') {
      return;
    }
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (
      !isPincodesLoading &&
      !isUsersLoading &&
      !isReturnOrdersAdminSelectLoading &&
      !isToysLoading &&
      !isLibrariesLoading &&
      !filterIsEnd
    ) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'returnOrderNumber':
              returnOrdersAdminSelect({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'deliveryPartnerName':
              users({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                    roles: [USER_ROLES?.DELIVERY?.key],
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'productName':
              toys({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'pincodes':
              pincodes({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: {
                    field: 'pincode',
                    order: 'ASC',
                  },
                },
              });
              break;

            case 'name':
              libraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'returnOrderNumber':
        returnOrdersAdminSelect({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'deliveryPartnerName':
        users({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
              roles: [USER_ROLES?.DELIVERY?.key],
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'productName':
        toys({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'pincodes':
        pincodes({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: {
              field: 'pincode',
              order: 'ASC',
            },
          },
        });
        break;

      case 'name':
        libraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt' || dataIndex === 'returnedOn') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'deliveryType') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={DELIVERY_TYPE_OPTIONS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ||
      finalWhereFilters?.[dataIndex] === DELIVERY_TYPE?.PAID?.value ||
      finalWhereFilters?.[dataIndex] === DELIVERY_TYPE?.FREE?.value ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const columns = [
    {
      title: 'PRODUCT RETURN ID',
      dataIndex: 'returnOrderNumber',
      key: 'returnOrderNumber',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      width: 150,
      render: (_, record) => record?.returnOrder?.returnOrderNumber,
      ...filterPopup('returnOrderNumber'),
    },
    {
      title: 'PRODUCT NAME',
      dataIndex: 'productName',
      key: 'productName',
      ellipsis: true,
      align: 'left',
      width: 250,
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isToyPermission &&
          record?.orderItem?.toy?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/view/${record?.orderItem?.toy?.id}`,
          ),
        className: `${
          isToyPermission && record?.orderItem?.toy?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const toyData = record?.orderItem?.toy;
        return (
          <>
            <div className="table-data-with-id">{toyData?.name || '-'}</div>
            {toyData?.toyNumber && (
              <div className="font-size-12">({toyData?.toyNumber})</div>
            )}
          </>
        );
      },
      ...filterPopup('productName'),
    },
    {
      title: 'ORDER ID',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      width: 150,
      render: (_, record) => record?.order?.orderNumber,
    },
    {
      title: 'DELIVERY TYPE',
      dataIndex: 'deliveryType',
      key: 'deliveryType',
      ellipsis: true,
      align: 'left',
      width: 150,
      className: 'max-width-column',
      render: (_, record) =>
        DELIVERY_TYPE?.[record?.order?.deliveryType]?.label,
      ...filterPopup('deliveryType'),
    },
    {
      title: 'USER NAME',
      dataIndex: 'userName',
      key: 'userName',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isUserPermission &&
          record?.returnOrder?.user?.id &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.returnOrder?.user?.id}/${record?.returnOrder?.user?.userNumber}`,
          ),
        className: `${
          isUserPermission &&
          record?.returnOrder?.user?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const userData = record?.returnOrder?.user;
        return (
          <>
            {userData?.firstName || userData?.lastName ? (
              <div className="table-data-with-id">{`${
                userData?.firstName ? userData?.firstName : ''
              } ${userData?.lastName ? userData?.lastName : ''}`}</div>
            ) : (
              '-'
            )}
            {userData?.userNumber ? (
              <div className="font-size-12">({userData?.userNumber})</div>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: 'LIBRARY NAME',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      ellipsis: true,
      className: `max-width-column`,
      width: 300,
      onCell: (record) => ({
        onClick: () =>
          isLibraryPermission &&
          record?.returnOrder?.library?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.returnOrder?.library?.id}/${record?.returnOrder?.library?.libraryNumber}`,
          ),
        className: `${
          isLibraryPermission &&
          record?.returnOrder?.library?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          <div className="table-data-with-id">
            {record?.returnOrder?.library?.name}
          </div>
          <div className="font-size-12">
            ({record?.returnOrder?.library?.libraryNumber})
          </div>
        </>
      ),
      ...filterPopup('name'),
    },
    {
      title: 'DELIVERY PARTNER NAME',
      dataIndex: 'deliveryPartnerName',
      key: 'deliveryPartnerName',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isDeliveryPartnerPermission &&
          record?.returnOrder?.deliveryPartner?.id &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}/view/${record?.returnOrder?.deliveryPartner?.id}`,
          ),
        className: `${
          isDeliveryPartnerPermission &&
          record?.returnOrder?.deliveryPartner?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const deliveryPartnerData = record?.returnOrder?.deliveryPartner;
        return (
          <>
            {deliveryPartnerData?.firstName || deliveryPartnerData?.lastName ? (
              <div className="table-data-with-id">{`${
                deliveryPartnerData?.firstName
                  ? deliveryPartnerData?.firstName
                  : ''
              } ${
                deliveryPartnerData?.lastName
                  ? deliveryPartnerData?.lastName
                  : ''
              }`}</div>
            ) : (
              '-'
            )}
            {deliveryPartnerData?.userNumber ? (
              <div className="font-size-12">
                ({deliveryPartnerData?.userNumber})
              </div>
            ) : (
              ''
            )}
          </>
        );
      },
      ...filterPopup('deliveryPartnerName'),
    },
    {
      title: 'PINCODE',
      dataIndex: 'pincode',
      key: 'pincode',
      ellipsis: true,
      align: 'left',
      width: 150,
      className: 'max-width-column',
      render: (_, record) => record?.returnOrder?.user?.pincode?.pincode || '-',
      ...filterPopup('pincodes'),
    },
    {
      title: 'PLACED ON',
      dataIndex: 'placedOn',
      key: 'placedOn',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.WITH_TIME),
      ...filterPopup('createdAt'),
    },
    {
      title: 'RETURNED ON',
      dataIndex: 'deliveredOn',
      key: 'deliveredOn',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.returnedOn, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('returnedOn'),
    },
    {
      title: 'PICKED UP ATTACHMENT',
      dataIndex: 'pickedUpAttachments',
      key: 'pickedUpAttachments',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const pickedUpAttachments = record?.deliveryAttachments?.filter(
          (attachment) =>
            [RETURN_ORDER_ITEM_STATUS?.PICKED_UP?.value, null]?.includes(
              attachment?.deliveryAttachmentsType,
            ),
        );

        const hasAttachments = pickedUpAttachments?.length > 0;
        const additionalCount = pickedUpAttachments?.length - 1;

        return (
          <span>
            {hasAttachments ? (
              <Image
                preview={{ visible: false }}
                width={20}
                height={20}
                src={pickedUpAttachments?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(pickedUpAttachments);
                }}
                className="pointer"
              />
            ) : (
              <span>No Images</span>
            )}
            {additionalCount > 0 && ` + ${additionalCount}`}
          </span>
        );
      },
    },
    {
      title: 'RETURNED ATTACHMENTS',
      dataIndex: 'returnedAttachments',
      key: 'returnedAttachments',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const returnedAttachments = record?.deliveryAttachments?.filter(
          (attachment) =>
            attachment?.deliveryAttachmentsType ===
            RETURN_ORDER_ITEM_STATUS?.RETURNED?.value,
        );

        const hasAttachments = returnedAttachments?.length > 0;
        const additionalCount = returnedAttachments?.length - 1;

        return (
          <span>
            {hasAttachments ? (
              <Image
                preview={{ visible: false }}
                width={20}
                height={20}
                src={returnedAttachments?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(returnedAttachments);
                }}
                className="pointer"
              />
            ) : (
              <span>No Images</span>
            )}
            {additionalCount > 0 && ` + ${additionalCount}`}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => RETURN_ORDER_ITEM_STATUS?.[record?.status]?.label,
      ...filterPopup('status', false),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 100,
      render: (_, record) => (
        <>
          <div className="action-button">
            <div className="mr-16">
              <Button
                type="primary"
                size="small"
                className="table-action-primary-btn"
                onClick={() => {
                  setOrder(record);
                  setIsContentMasterModalOpen(true);
                }}
              >
                View Content Logs
              </Button>
            </div>
            {hasUpdatePermission && (
              <Button
                type="primary"
                size="small"
                className="table-action-primary-btn"
                onClick={() => {
                  setIsRequestPrompts(true);
                  setOrder(record);
                }}
                disabled={
                  record?.status ===
                  RETURN_ORDER_ITEM_STATUS?.RETURN_CONFIRMED?.value
                }
              >
                Cancel Request
              </Button>
            )}
            <Tooltip title="Status Logs" placement="top" zIndex={4}>
              <InfoIcon
                onClick={() => {
                  setOrder(record);
                  setIsOrderStatusModalOpen(true);
                }}
                className="ml-16 pointer svg-icon"
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const expandedRowRender = (data) => {
    const nestedData = [
      {
        id: data?.id,
        productName: data?.orderItem?.toy?.name,
        ageGroups: data?.orderItem?.toy?.ageGroups,
        toyCondition: data?.toyCondition,
        images: data?.images,
        productRate: data?.productRate,
        toyId: data?.orderItem?.toy?.id,
        toyNumber: data?.orderItem?.toy?.toyNumber,
        brand: data?.orderItem?.toy?.brand?.name,
        videoUrl: data?.videoUrl,
        returnOrderNumber: data?.returnOrder?.returnOrderNumber,
        productImages: data?.orderItem?.toy?.images,
        packagingMaterial: data?.orderItem?.toy?.bag,
      },
    ];

    const nestedColumns = [
      {
        title: 'PRODUCT NAME',
        dataIndex: 'productName',
        key: 'productName',
        ellipsis: true,
        align: 'left',
        width: 250,
        className: `max-width-column`,
        onCell: (record) => ({
          onClick: () =>
            isToyPermission &&
            record?.toyId &&
            history?.push(
              `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/view/${record?.toyId}`,
            ),
          className: `${
            isToyPermission && record?.toyId && 'pointer table-cell'
          }`,
        }),
        render: (_, record) => (
          <>
            <div className="table-data-with-id">
              {record?.productName || '-'}
            </div>
            {record?.toyNumber && (
              <div className="font-size-12">({record?.toyNumber})</div>
            )}
          </>
        ),
      },
      {
        title: 'IMAGES',
        dataIndex: 'productImages',
        key: 'productImages',
        ellipsis: true,
        width: 100,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => {
          const images = record?.productImages;
          return (
            <span>
              {images?.length > 0 ? (
                <>
                  <Image
                    preview={{
                      visible: false,
                    }}
                    width={20}
                    height={20}
                    src={images?.[0]?.url}
                    onClick={(e) => {
                      e?.stopPropagation();
                      setVisible(true);
                      setToyImages(images);
                    }}
                    className="pointer"
                  />
                </>
              ) : (
                <span>No Images</span>
              )}
              {images?.length > 1 ? ` + ${images?.length - 1}` : ''}
            </span>
          );
        },
      },
      {
        title: 'BRAND',
        dataIndex: 'brand',
        key: 'brand',
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) => record?.brand || '-',
      },
      {
        title: 'PACKAGING MATERIAL',
        dataIndex: 'packagingMaterial',
        key: 'packagingMaterial',
        ellipsis: true,
        width: 250,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => (
          <>
            {record?.packagingMaterial ? (
              <div>
                <span className="mr-8">
                  {record?.packagingMaterial?.name} (
                  {record?.packagingMaterial?.size})
                </span>
                <Image
                  preview={{ visible: false }}
                  width={22}
                  src={record?.packagingMaterial?.image}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setPreviewVisible(true);
                    setToyPreviewImage(record?.packagingMaterial?.image);
                  }}
                  className="pointer"
                />
              </div>
            ) : (
              '-'
            )}
          </>
        ),
      },
      {
        title: 'AGE GROUPS',
        dataIndex: 'ageGroups',
        key: 'ageGroups',
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) =>
          record?.ageGroups?.map((ageGroup) =>
            ageGroup?.minAge > 18 ? (
              <Tag key={ageGroup?.id}>
                {Math.floor(ageGroup?.minAge / 12)} -{' '}
                {Math.floor(ageGroup?.maxAge / 12)} Years
              </Tag>
            ) : (
              <Tag key={ageGroup?.id}>
                {ageGroup?.minAge} - {ageGroup?.maxAge} Months
              </Tag>
            ),
          ),
      },
      {
        title: 'RETURN TOY IMAGES',
        dataIndex: 'image',
        key: 'image',
        ellipsis: true,
        width: 100,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => (
          <span>
            {record?.images?.length > 0 ? (
              <>
                <Image
                  preview={{
                    visible: false,
                  }}
                  width={20}
                  height={20}
                  src={record?.images?.[0]?.url}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setVisible(true);
                    setToyImages(record?.images);
                  }}
                  className="pointer"
                />
              </>
            ) : (
              <span>No Images</span>
            )}
            {record?.images?.length > 1
              ? ` + ${record?.images?.length - 1}`
              : ''}
          </span>
        ),
      },
      {
        title: 'VIDEO',
        dataIndex: 'video',
        key: 'video',
        ellipsis: true,
        width: 100,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => (
          <span>
            {record?.videoUrl ? (
              <Tooltip title="Play" placement="top" zIndex={4}>
                <PlayIcon
                  onClick={() => {
                    setOrderItem(record);
                    setIsVideoModalOpen(true);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              '-'
            )}
          </span>
        ),
      },
      {
        title: 'PRODUCT RATING',
        dataIndex: 'productRate',
        key: 'productRate',
        ellipsis: true,
        align: 'left',
        width: 250,
        className: 'max-width-column',
        render: (_, record) => (
          <div className="action-button">
            {record?.productRate === PRODUCT_RATE?.LIKE ? (
              <Tooltip title="Rated" placement="top">
                <LikeRatingIcon className="mr-16 svg-icon" />
              </Tooltip>
            ) : (
              <Tooltip title="Unrated" placement="top">
                <DislikeRatingIcon className="mr-16 svg-icon" />
              </Tooltip>
            )}
          </div>
        ),
      },
    ];

    return (
      <CommonTable
        columns={nestedColumns}
        dataSource={nestedData || []}
        pagination={false}
        rowKey={(record) => record?.id}
        className="nested-table"
      />
    );
  };

  const locale = {
    emptyText: isEmptyOrderList ? '' : <span />,
  };

  const handleCancel = () => {
    setIsContentMasterModalOpen(false);
    setOrder({});
  };

  return (
    <>
      <ContentMasterModal
        isModalOpen={isContentMasterModalOpen}
        contentHistory={order?.contentHistory}
        handleCancel={handleCancel}
        isReturn
      />
      <ReturnItemStatusModal
        title="Status Logs"
        isModalOpen={isOrderStatusModalOpen}
        setIsModalOpen={setIsOrderStatusModalOpen}
        statusHistories={order?.statusHistory}
      />
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isRequestPrompts}
        onOk={handleReturnRequest}
        onCancel={() => setIsRequestPrompts(false)}
        okButtonProps={{ loading: isRequestLoadings }}
      >
        Are you sure you want to
        <strong> Cancel return request </strong>for this?
      </Modal>
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <CommonPreview
        visible={previewVisible}
        setVisible={setPreviewVisible}
        image={toyPreviewImage}
        setImage={setToyPreviewImage}
      />
      <ToyVideoModal
        title={`Video - ${orderItem?.returnOrderNumber}`}
        isModalOpen={isVideoModalOpen}
        setIsModalOpen={setIsVideoModalOpen}
        videoUrl={orderItem?.videoUrl}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={orderList || []}
        loading={isOrderLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record),
        }}
      />
    </>
  );
};

export default AllReturnTable;
