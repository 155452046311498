import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  LIMIT,
  PLAN_NAMES,
  PLAN_TYPES,
  SORT,
  SUBSCRIPTION_TYPE,
} from '../../../../../common/constants';
import SelectComponent from '../../../../../components/SelectComponent';
import { SUBSCRIPTION_PLANS_ADMIN_SELECT } from '../../../../subscription/graphql/Queries';

const { Option } = Select;

const SubscriptionPlanSelect = (props) => {
  const {
    planData,
    isModalOpen = true,
    type,
    isTrialPlanDisplay = true,
    isPlanListEmpty,
    setIsPlanListEmpty,
    ...rest
  } = props;
  const [planList, setPlanList] = useState([]);
  const [planSearchTerm, setPlanSearchTerm] = useState('');
  const [isAllPlansFetched, setIsAllPlansFetched] = useState(false);
  const [isPlansAllowClear, setIsPlansAllowClear] = useState(false);
  const [isPlansDropdownVisible, setIsPlansDropdownVisible] = useState(false);
  const [isFetchMorePlansLoading, setIsFetchMorePlansLoading] = useState(false);

  const [subscriptionPlansAdmin, { loading: isPlanLoading }] = useLazyQuery(
    SUBSCRIPTION_PLANS_ADMIN_SELECT,
    {
      onCompleted: (response) => {
        if (response?.subscriptionPlansAdmin?.data?.length < LIMIT) {
          setIsAllPlansFetched(true);
        }
        setIsFetchMorePlansLoading(false);
        if (planData) {
          setPlanList(
            uniqBy(
              [
                {
                  ...planData,
                  name: planData?.name,
                  id: planData?.id,
                  type: planData?.type,
                },
                ...planList,
                ...response?.subscriptionPlansAdmin?.data,
              ],
              'id',
            ),
          );
          return;
        }
        setPlanList([...planList, ...response?.subscriptionPlansAdmin?.data]);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    setPlanSearchTerm('');
    setPlanList([]);
    if (isModalOpen) {
      subscriptionPlansAdmin({
        variables: {
          filter: {
            skip: 0,
            search: planSearchTerm,
            isAllPlan: true,
          },
          sort: {
            field: 'name',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllPlansFetched(false);
  }, [isPlansDropdownVisible, isModalOpen]);

  const handleLibrariesScroll = (event) => {
    if (!isAllPlansFetched && !isPlanLoading) {
      const target = event?.target;
      const currentLength = planList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMorePlansLoading(true);
        subscriptionPlansAdmin({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: planSearchTerm,
              isAllPlan: true,
            },
            sort: {
              field: 'name',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleLibrariesSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllPlansFetched(false);
    setPlanList([]);
    setPlanSearchTerm(trimSearch);
    subscriptionPlansAdmin({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
          isAllPlan: true,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleLibrariesScrollDebounce = debounce(handleLibrariesScroll, 500);
  const handleSearchLibrariesDebounce = debounce(handleLibrariesSearch, 500);

  useEffect(() => {
    handleLibrariesScrollDebounce?.cancel();
  }, [handleLibrariesScrollDebounce]);

  useEffect(() => {
    handleSearchLibrariesDebounce?.cancel();
  }, [handleSearchLibrariesDebounce]);

  useEffect(() => {
    if (isPlanLoading) {
      setIsPlansAllowClear(false);
    } else {
      setIsPlansAllowClear(true);
    }
  }, [isPlanLoading]);

  const filteredPlan = planList
    ?.filter((plan) => {
      const filterPlans = type ? plan?.name !== PLAN_NAMES?.TRIAL?.value : plan;
      return !isTrialPlanDisplay ? filterPlans : plan;
    })
    ?.filter((plan) => {
      const isUpdateSub = type === SUBSCRIPTION_TYPE?.UPDATE_SUB?.value;
      return isUpdateSub
        ? plan?.name !== PLAN_NAMES?.TRIAL?.value && plan?.id !== planData?.id
        : true;
    });

  useEffect(() => {
    if (isPlanListEmpty === false || isPlanListEmpty === true) {
      if (filteredPlan?.length === 0) {
        setIsPlanListEmpty(true);
      } else {
        setIsPlanListEmpty(false);
      }
    }
  }, [filteredPlan]);

  return (
    <SelectComponent
      showSearch={false}
      loading={
        (isPlanLoading && isPlansDropdownVisible) || isFetchMorePlansLoading
      }
      placeholder="Select Plan"
      optionFilterProp="children"
      allowClear={isPlansAllowClear}
      onPopupScroll={handleLibrariesScrollDebounce}
      onSearch={handleSearchLibrariesDebounce}
      onDropdownVisibleChange={(visible) => setIsPlansDropdownVisible(visible)}
      filterOption={false}
      notFoundContent={
        !isFetchMorePlansLoading && isPlanLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {filteredPlan?.map((plan) => {
        const planVersion = plan?.subscriptionFeatures?.planVersion;
        return (
          <Option
            key={plan?.id}
            value={plan?.id}
            data={plan}
            price={plan?.price}
          >
            {PLAN_NAMES?.[plan?.name]?.key} - {PLAN_TYPES?.[plan?.type]?.key}
            {planVersion ? ` (${planVersion})` : ''}
          </Option>
        );
      })}
    </SelectComponent>
  );
};

export default SubscriptionPlanSelect;
