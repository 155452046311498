import { Button } from 'antd';
import React, { useState } from 'react';
import { ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import Portal from '../../../../../components/Portal';
import EndUsersTabs from '../components/EndUsersTabs';
import SubscriptionInfo from '../components/SubscriptionInfo';

const SubscriptionDetails = ({
  match: { params: { id, userNumber } = {} } = {},
}) => {
  const [isDeliveryModalOpen, setIsDeliveryModalOpen] = useState(false);
  const [isHoldingModalOpen, setIsHoldingModalOpen] = useState(false);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [isExpiryDateModalOpen, setIsExpiryDateModalOpen] = useState(false);
  const [isSubscriptionBtnDisable, setIsSubscriptionBtnDisable] = useState(
    true,
  );
  const [isExpiryDateBtnDisable, setIsExpiryDateBtnDisable] = useState(true);
  const [isDeliveryBtnDisable, setIsDeliveryBtnDisable] = useState(true);
  const [isUpdatePlanModalOpen, setIsUpdatePlanModalOpen] = useState(false);
  const [isUpdatePlanBtnDisable, setIsUpdatePlanBtnDisable] = useState(true);
  const [isPlanListEmpty, setIsPlanListEmpty] = useState(false);

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`}
        />
        <div className="portal-header">Edit End User</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            className="common-button mr-16"
            size="middle"
            onClick={() => setIsSubscriptionModalOpen(true)}
            disabled={isSubscriptionBtnDisable}
          >
            Activate Subscription
          </Button>
          <Button
            className="common-button mr-16"
            size="middle"
            onClick={() => setIsExpiryDateModalOpen(true)}
            disabled={isExpiryDateBtnDisable}
          >
            Edit Expiry Date
          </Button>
          <Button
            className="common-button mr-16"
            size="middle"
            onClick={() => setIsDeliveryModalOpen(true)}
            disabled={isDeliveryBtnDisable}
          >
            Edit Free Deliveries
          </Button>
          <Button
            className="common-button mr-8"
            size="middle"
            onClick={() => setIsHoldingModalOpen(true)}
          >
            Edit Toy Holdings
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={() => setIsUpdatePlanModalOpen(true)}
            disabled={isUpdatePlanBtnDisable}
          >
            Update Subscription
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            className="common-button mr-8"
            size="middle"
            onClick={() => setIsSubscriptionModalOpen(true)}
            disabled={isSubscriptionBtnDisable}
          >
            Activate Subscription
          </Button>
          <Button
            className="common-button mr-8"
            size="middle"
            onClick={() => setIsExpiryDateModalOpen(true)}
            disabled={isExpiryDateBtnDisable}
          >
            Edit Expiry Date
          </Button>
          <Button
            className="common-button mr-8"
            size="middle"
            onClick={() => setIsDeliveryModalOpen(true)}
            disabled={isDeliveryBtnDisable}
          >
            Edit Free Deliveries
          </Button>
          <Button
            className="common-button mr-8"
            size="middle"
            onClick={() => setIsHoldingModalOpen(true)}
          >
            Edit Toy Holdings
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={() => setIsUpdatePlanModalOpen(true)}
            disabled={isUpdatePlanBtnDisable}
          >
            Update Subscription
          </Button>
        </div>
      </Portal>
      <EndUsersTabs endUserId={id} endUserNumber={userNumber}>
        <div className="full-height-without-details">
          <SubscriptionInfo
            isDeliveryModalOpen={isDeliveryModalOpen}
            setIsDeliveryModalOpen={setIsDeliveryModalOpen}
            isHoldingModalOpen={isHoldingModalOpen}
            setIsHoldingModalOpen={setIsHoldingModalOpen}
            isSubscriptionModalOpen={isSubscriptionModalOpen}
            setIsSubscriptionModalOpen={setIsSubscriptionModalOpen}
            isExpiryDateModalOpen={isExpiryDateModalOpen}
            setIsExpiryDateModalOpen={setIsExpiryDateModalOpen}
            userId={id}
            isView={false}
            setIsSubscriptionBtnDisable={setIsSubscriptionBtnDisable}
            setIsExpiryDateBtnDisable={setIsExpiryDateBtnDisable}
            setIsDeliveryBtnDisable={setIsDeliveryBtnDisable}
            isUpdatePlanModalOpen={isUpdatePlanModalOpen}
            setIsUpdatePlanModalOpen={setIsUpdatePlanModalOpen}
            setIsUpdatePlanBtnDisable={setIsUpdatePlanBtnDisable}
            isPlanListEmpty={isPlanListEmpty}
            setIsPlanListEmpty={setIsPlanListEmpty}
          />
        </div>
      </EndUsersTabs>
    </>
  );
};

export default SubscriptionDetails;
