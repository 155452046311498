import { useLazyQuery } from '@apollo/client';
import { Empty, Image, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../../../common/constants';
import CommonPreview from '../../../../../components/CommonPreview';
import SelectComponent from '../../../../../components/SelectComponent';
import { BAGS_ADMIN } from '../graphql/Queries';

const { Option } = Select;

const PackagingMaterialSelect = (props) => {
  const { materialDetail, ...rest } = props;
  const [materialList, setMaterialList] = useState([]);
  const [materialsSearchTerm, setMaterialsSearchTerm] = useState('');
  const [isAllMaterialsFetched, setIsAllMaterialsFetched] = useState(false);
  const [isMaterialsAllowClear, setIsMaterialsAllowClear] = useState(false);
  const [isMaterialsDropdownVisible, setIsMaterialsDropdownVisible] = useState(
    false,
  );
  const [
    isFetchMoreMaterialsLoading,
    setIsFetchMoreMaterialsLoading,
  ] = useState(false);
  const [sizeImage, setSizeImage] = useState();
  const [visible, setVisible] = useState(false);

  const [bagsAdmin, { loading: isMaterialsLoading }] = useLazyQuery(
    BAGS_ADMIN,
    {
      onCompleted: (response) => {
        if (response?.bagsAdmin?.data?.length < LIMIT) {
          setIsAllMaterialsFetched(true);
        }
        setIsFetchMoreMaterialsLoading(false);
        if (materialDetail) {
          setMaterialList(
            uniqBy(
              [materialDetail, ...materialList, ...response?.bagsAdmin?.data],
              'id',
            ),
          );
          return;
        }
        setMaterialList([...materialList, ...response?.bagsAdmin?.data]);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    setMaterialsSearchTerm('');
    setMaterialList([]);
    bagsAdmin({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: materialsSearchTerm,
          isArchived: false,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
    setIsAllMaterialsFetched(false);
  }, [isMaterialsDropdownVisible]);

  const handleMaterialsScroll = (event) => {
    if (!isAllMaterialsFetched && !isMaterialsLoading) {
      const target = event?.target;
      const currentLength = materialList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreMaterialsLoading(true);
        bagsAdmin({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: materialsSearchTerm,
            },
            sort: {
              field: 'name',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleMaterialsSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllMaterialsFetched(false);
    setMaterialList([]);
    setMaterialsSearchTerm(trimSearch);
    bagsAdmin({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleMaterialsScrollDebounce = debounce(handleMaterialsScroll, 500);
  const handleSearchMaterialsDebounce = debounce(handleMaterialsSearch, 500);

  useEffect(() => {
    handleMaterialsScrollDebounce?.cancel();
  }, [handleMaterialsScrollDebounce]);

  useEffect(() => {
    handleSearchMaterialsDebounce?.cancel();
  }, [handleSearchMaterialsDebounce]);

  useEffect(() => {
    if (isMaterialsLoading) {
      setIsMaterialsAllowClear(false);
    } else {
      setIsMaterialsAllowClear(true);
    }
  }, [isMaterialsLoading]);

  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={sizeImage}
        setImage={setSizeImage}
      />
      <SelectComponent
        showSearch
        loading={
          (isMaterialsLoading && isMaterialsDropdownVisible) ||
          isFetchMoreMaterialsLoading
        }
        placeholder="Select Packaging Material"
        optionFilterProp="children"
        allowClear={isMaterialsAllowClear}
        onPopupScroll={handleMaterialsScrollDebounce}
        onSearch={handleSearchMaterialsDebounce}
        onDropdownVisibleChange={(isVisible) =>
          setIsMaterialsDropdownVisible(isVisible)
        }
        filterOption={false}
        notFoundContent={
          !isFetchMoreMaterialsLoading && isMaterialsLoading ? (
            <span>Loading...</span>
          ) : (
            <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        {...rest}
      >
        {materialList?.map((material) => (
          <Option key={material?.id} value={material?.id}>
            <div>
              <span className="mr-8">
                {material?.name} ({material?.size})
              </span>
              <Image
                preview={{
                  visible: false,
                }}
                width={22}
                src={material?.image}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setSizeImage(material?.image);
                }}
                className="pointer"
              />
            </div>
          </Option>
        ))}
      </SelectComponent>
    </>
  );
};

export default PackagingMaterialSelect;
