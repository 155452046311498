import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Image,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import { React, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ReactComponent as CommentIcon } from '../../../assets/svg/comment-dots-solid.svg';
import { ReactComponent as PlayIcon } from '../../../assets/svg/play-solid.svg';
import { ReactComponent as FilterIcon } from '../../../assets/svg/sliders-solid.svg';
import {
  BREAKPOINTS,
  DAMAGE_REQUEST_STATUS,
  DATE_FORMATS,
  LIMIT,
  REQUEST_STATUS,
  REQUEST_TYPES,
  SORT,
  STATUS_LIBRARY_DAMAGE_FILTER,
  USER_ROLES,
} from '../../../common/constants';
import { formatDate, hasNonEmptyValueObj } from '../../../common/utils';
import CommonPreviewTable from '../../../components/CommonPreviewTable';
import CommonTable from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import SearchComponent from '../../../components/SearchComponent';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import ContentMasterModal from '../../../modules/orders/modules/all/components/ContentMasterModal';
import { UPDATE_REQUEST_LIBRARY } from '../graphql/Mutations';
import {
  LIBRARIES,
  LIBRARY_DAMAGE_REQUESTS_LIBRARY,
  USERS,
} from '../graphql/Queries';
import AllFilterDamageModal from './AllFilterDamageModal';
import CommentModal from './CommentModal';
import DamageList from './DamageList';
import ToyVideoModal from './ToyVideoModal';
import ViewCommentsModal from './ViewCommentsModal';

const { RangePicker } = DatePicker;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialDamageFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const initialDamageSort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

const roleFilter = [USER_ROLES?.DELIVERY?.key];
let filterScrollDebounceJob = null;

const DamageTable = () => {
  const {
    state: { isDesktop },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [damageList, setDamageList] = useState([]);
  const [damageSearchTerm, setDamageSearchTerm] = useState('');
  const [isEmptyDamageList, setIsEmptyDamageList] = useState(false);
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [damage, setDamage] = useState({});

  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);
  const [toyData, setToyData] = useState({});
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const [isCommentPrompts, setIsCommentPrompts] = useState(false);
  const [isViewCommentPrompts, setIsViewCommentPrompts] = useState(false);

  const [isDamageLoading, setIsDamageLoading] = useState(true);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);
  const [isAllFilterModalOpen, setIsAllFilterModalOpen] = useState(false);
  const [isFetchMoreDamagesLoading, setIsFetchMoreDamagesLoading] = useState(
    false,
  );
  const [isActive, setIsActive] = useState(true);
  const [isContentMasterModalOpen, setIsContentMasterModalOpen] = useState(
    false,
  );
  const [isPendingComment, setIsPendingComment] = useState(false);

  const [requestsLibrary] = useLazyQuery(LIBRARY_DAMAGE_REQUESTS_LIBRARY, {
    onCompleted: (response) => {
      if (isDesktop) {
        setDamageList([...response?.requestsLibrary?.data]);
      } else {
        setDamageList([...damageList, ...response?.requestsLibrary?.data]);
      }
      if (
        response?.requestsLibrary?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyDamageList(true);
      } else {
        setIsEmptyDamageList(false);
      }
      if (response?.requestsLibrary?.data?.length < LIMIT) {
        setIsActive(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.requestsLibrary?.count,
      };
      setPaginationProp(pagination);
      setIsDamageLoading(false);
      setIsFetchMoreDamagesLoading(false);
    },
    fetchPolicy: 'no-cache',
    onError() {},
  });

  const [libraries, { loading: isLibrariesLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [users, { loading: isUsersLoading }] = useLazyQuery(USERS, {
    onCompleted: (response) => {
      if (response?.users?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.users?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.users?.data, (item) =>
        optionsCopy?.push({
          label: `${item?.firstName} ${item?.lastName}`,
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [updateRequestLibrary] = useMutation(UPDATE_REQUEST_LIBRARY, {
    onError: () => {},
  });

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsDamageLoading(true);
    setDamageList([]);
    requestsLibrary({
      variables: {
        filter: {
          ...initialDamageFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: damageSearchTerm,
          type: REQUEST_TYPES?.LIBRARY_DAMAGE,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          libraryIds: finalWhereFilters?.name,
          deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
  }, [isDesktop]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsDamageLoading(true);
    setSortedInfo(sorter);
    requestsLibrary({
      variables: {
        filter: {
          ...initialDamageFilter,
          skip,
          limit: pagination?.pageSize,
          search: damageSearchTerm,
          type: REQUEST_TYPES?.LIBRARY_DAMAGE,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          libraryIds: finalWhereFilters?.name,
          deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setDamageSearchTerm(trimValue);
    setDamageList([]);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsDamageLoading(true);
    requestsLibrary({
      variables: {
        filter: {
          ...initialDamageFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          type: REQUEST_TYPES?.LIBRARY_DAMAGE,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          libraryIds: finalWhereFilters?.name,
          deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
  };

  const handleFilter = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsDamageLoading(true);
    setIsAllFilterModalOpen(false);
    setDamageList([]);
    setIsActive(true);
    const filters = {
      status: values?.status,
      createdAt: values?.createdAt?.[1]
        ? { from: values?.createdAt?.[0], to: values?.createdAt?.[1] }
        : null,
    };
    setWhereFilter(filters);
    setFinalWhereFilters(filters);
    requestsLibrary({
      variables: {
        filter: {
          ...initialDamageFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: damageSearchTerm,
          type: REQUEST_TYPES?.LIBRARY_DAMAGE,
          dateRange: {
            from: values?.createdAt?.[0],
            to: values?.createdAt?.[1],
          },
          status: values?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
  };

  const handleAddComment = async (values) => {
    setIsCommentLoading(true);
    const response = await updateRequestLibrary({
      variables: {
        where: {
          id: damage?.id,
        },
        data: {
          libraryDamage: {
            comment: values?.comment?.trim() || '',
          },
          status: REQUEST_STATUS?.APPROVED?.value,
        },
      },
    });
    if (response?.data) {
      setIsDamageLoading(true);
      setIsCommentPrompts(false);
      form?.resetFields();
      requestsLibrary({
        variables: {
          filter: {
            ...initialDamageFilter,
            limit: paginationProp?.pageSize || LIMIT,
            search: damageSearchTerm,
            type: REQUEST_TYPES?.LIBRARY_DAMAGE,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            libraryIds: finalWhereFilters?.name,
            deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialDamageSort,
        },
      });
    }
    setIsCommentLoading(false);
  };

  const handleDamageScroll = () => {
    if (!isFetchMoreDamagesLoading && !isDamageLoading) {
      const currentLength = damageList?.length;
      setIsFetchMoreDamagesLoading(true);
      requestsLibrary({
        variables: {
          filter: {
            ...initialDamageFilter,
            skip: currentLength,
            limit: paginationProp?.pageSize || LIMIT,
            search: damageSearchTerm,
            type: REQUEST_TYPES?.LIBRARY_DAMAGE,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialDamageSort,
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-use-before-define
  const infiniteScrollRef = useInfiniteScroll(handleDamageScroll, isActive);

  const getFilterData = (confirm) => {
    setIsDamageLoading(true);
    requestsLibrary({
      variables: {
        filter: {
          ...initialDamageFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: damageSearchTerm,
          type: REQUEST_TYPES?.LIBRARY_DAMAGE,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
          libraryIds: whereFilter?.name,
          deliveryPartnerIds: whereFilter?.deliveryPartnerName,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsDamageLoading(true);
    requestsLibrary({
      variables: {
        filter: {
          ...initialDamageFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: damageSearchTerm,
          type: REQUEST_TYPES?.LIBRARY_DAMAGE,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
          libraryIds: whereFiltersCopy?.name,
          deliveryPartnerIds: whereFiltersCopy?.deliveryPartnerName,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(STATUS_LIBRARY_DAMAGE_FILTER, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.key });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'name':
          libraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'deliveryPartnerName':
          users({
            variables: {
              filter: {
                ...initialTableFilter,
                roles: roleFilter,
              },
              sort: initialTableSort,
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (!isLibrariesLoading && !isUsersLoading && !filterIsEnd) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'name':
              libraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'deliveryPartnerName':
              users({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                    roles: roleFilter,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'name':
        libraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'deliveryPartnerName':
        users({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
              roles: roleFilter,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const columns = [
    {
      title: 'USER ID',
      dataIndex: 'userId',
      key: 'userId',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.damager?.userNumber || '-',
    },
    {
      title: 'REQUEST ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 170,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.requestNumber,
    },
    {
      title: 'DELIVERY PARTNER NAME',
      dataIndex: 'deliveryPartnerName',
      key: 'deliveryPartnerName',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        `${record?.returnOrderItemDetails?.[0]?.returnOrder?.deliveryPartner?.firstName} ${record?.returnOrderItemDetails?.[0]?.returnOrder?.deliveryPartner?.lastName}`,
    },
    {
      title: 'PRODUCT NAME',
      dataIndex: 'productName',
      key: 'productName',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        `${record?.returnOrderItemDetails?.[0]?.libraryInventory?.toy?.name}`,
    },
    {
      title: 'REQUEST DATE',
      dataIndex: 'requestDate',
      key: 'requestDate',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('createdAt'),
    },
    {
      title: 'IMAGES',
      dataIndex: 'images',
      key: 'images',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.images?.length > 0 ? (
            <>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={record?.images?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(record?.images);
                }}
                className="pointer"
              />
            </>
          ) : (
            <span>No Images</span>
          )}
          {record?.images?.length > 1 ? ` + ${record?.images?.length - 1}` : ''}
        </span>
      ),
    },
    {
      title: 'VIDEO',
      dataIndex: 'video',
      key: 'video',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.videoUrl ? (
            <Tooltip title="Play" placement="top" zIndex={4}>
              <PlayIcon
                onClick={() => {
                  setToyData(record);
                  setIsVideoModalOpen(true);
                }}
                className="mr-16 pointer svg-icon"
              />
            </Tooltip>
          ) : (
            '-'
          )}
        </span>
      ),
    },
    {
      title: 'DAMAGE DESCRIPTION',
      dataIndex: 'damageDescription',
      key: 'damageDescription',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => (
        <Tooltip
          title={
            record?.description ? record?.description : 'No Damage Description'
          }
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {record?.description || '-'}
        </Tooltip>
      ),
    },
    {
      title: 'PENDING COMMENT',
      dataIndex: 'pendingComment',
      key: 'pendingComment',
      ellipsis: true,
      width: 150,
      render: (_, record) =>
        [
          REQUEST_STATUS?.RESOLVED?.value,
          REQUEST_STATUS?.APPROVED?.value,
          REQUEST_STATUS?.PENDING?.value,
          REQUEST_STATUS?.REJECTED?.value,
        ]?.includes(record?.status) && record?.metaData?.pendingComment ? (
          <>
            <div className="action-button">
              <CommentIcon
                className="mr-16 pointer svg-icon"
                onClick={() => {
                  form?.setFieldsValue({
                    comment: record?.metaData?.pendingComment?.comment,
                    reason: record?.metaData?.pendingComment?.reason,
                  });
                  setIsPendingComment(true);
                  setDamage(record);
                  setIsViewCommentPrompts(true);
                }}
              />
            </div>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: 'RESOLVING COMMENT',
      dataIndex: 'resolvingComment',
      key: 'resolvingComment',
      ellipsis: true,
      width: 150,
      render: (_, record) =>
        ([
          REQUEST_STATUS?.RESOLVED?.value,
          REQUEST_STATUS?.APPROVED?.value,
          REQUEST_STATUS?.PENDING?.value,
          REQUEST_STATUS?.REJECTED?.value,
        ]?.includes(record?.status) &&
          record?.metaData?.resolveComment) ||
        record?.metaData?.comment ? (
          <>
            <div className="action-button">
              <CommentIcon
                className="mr-16 pointer svg-icon"
                onClick={() => {
                  form?.setFieldsValue({
                    comment:
                      record?.metaData?.resolveComment?.comment ||
                      record?.metaData?.comment,
                    reason: record?.metaData?.resolveComment?.reason,
                  });
                  setDamage(record);
                  setIsPendingComment(false);
                  setIsViewCommentPrompts(true);
                }}
              />
            </div>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={DAMAGE_REQUEST_STATUS?.[record?.status]?.color}
          text={DAMAGE_REQUEST_STATUS?.[record?.status]?.text}
        />
      ),
      ...filterPopup('status', false),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <div className="action-button">
          <div className="mr-16">
            <Button
              type="primary"
              size="small"
              className="table-action-primary-btn"
              onClick={() => {
                setIsPendingComment(false);
                setIsCommentPrompts(true);
                setDamage(record);
              }}
              disabled={[
                REQUEST_STATUS?.PENDING?.value,
                REQUEST_STATUS?.APPROVED?.value,
                REQUEST_STATUS?.REJECTED?.value,
              ]?.includes(record?.status)}
            >
              Accept
            </Button>
          </div>
          <Button
            type="primary"
            size="small"
            className="table-action-primary-btn"
            onClick={() => {
              setDamage(record);
              setIsContentMasterModalOpen(true);
            }}
          >
            View Content Logs
          </Button>
        </div>
      ),
    },
  ];

  const locale = {
    emptyText: isEmptyDamageList ? '' : <span />,
  };

  const handleCancel = () => {
    setIsContentMasterModalOpen(false);
    setDamage({});
  };

  return (
    <>
      <ContentMasterModal
        isModalOpen={isContentMasterModalOpen}
        contentHistory={damage?.returnOrderItemDetails?.[0]?.contentHistory}
        handleCancel={handleCancel}
        isReturn
      />
      <ToyVideoModal
        title={`Video - ${toyData?.createdBy?.userNumber}`}
        isModalOpen={isVideoModalOpen}
        setIsModalOpen={setIsVideoModalOpen}
        videoUrl={toyData?.videoUrl}
      />
      <CommentModal
        form={form}
        isModalOpen={isCommentPrompts}
        setIsModalOpen={setIsCommentPrompts}
        isBtnLoading={isCommentLoading}
        onFinish={handleAddComment}
        isPendingComment={isPendingComment}
      />
      <ViewCommentsModal
        form={form}
        isModalOpen={isViewCommentPrompts}
        setIsModalOpen={setIsViewCommentPrompts}
        libraryComments={damage?.metaData?.actionsByLibrary}
        status={damage?.status}
        metadata={damage?.metaData}
        isPendingComment={isPendingComment}
        onFinish={handleAddComment}
        isLibrarian
        damage={damage}
      />
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <div className="d-flex justify-end mb-16 align-center">
        <SearchComponent
          className={isDesktop ? 'search-box' : ''}
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
        {!isDesktop && (
          <Badge dot={hasNonEmptyValueObj(finalWhereFilters)} size="small">
            <FilterIcon
              className="mobile-svg-icon ml-16"
              onClick={() => setIsAllFilterModalOpen(true)}
            />
          </Badge>
        )}
      </div>
      {isDesktop ? (
        <CommonTable
          locale={locale}
          columns={columns}
          data={damageList || []}
          loading={isDamageLoading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(record) => record?.id}
        />
      ) : (
        <div className="mobile-container no-scroll-bar">
          <AllFilterDamageModal
            onFinish={handleFilter}
            isModalOpen={isAllFilterModalOpen}
            setIsModalOpen={setIsAllFilterModalOpen}
            finalWhereFilters={finalWhereFilters}
            setFinalWhereFilters={setFinalWhereFilters}
          />
          <DamageList
            damageList={damageList}
            infiniteScrollRef={infiniteScrollRef}
            isFetchMoreDamagesLoading={isFetchMoreDamagesLoading}
            setVisible={setVisible}
            setToyImages={setToyImages}
            isEmptyDamageList={isEmptyDamageList}
            isDamageLoading={isDamageLoading}
            setDamage={setDamage}
            setIsCommentPrompts={setIsCommentPrompts}
            setIsContentMasterModalOpen={setIsContentMasterModalOpen}
          />
        </div>
      )}
    </>
  );
};

export default DamageTable;
