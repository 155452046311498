import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Image,
  Modal,
  Radio,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, find, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import { ReactComponent as FilterIcon } from '../../../../../assets/svg/sliders-solid.svg';
import {
  CANCELLATION_TYPE,
  DATE_FORMATS,
  DELIVERY_TYPE,
  DELIVERY_TYPE_OPTIONS,
  FULFILLMENT_STATUS,
  LIBRARY_ALL_DELIVERY_TABLE_ACTIONS_OPTIONS,
  LIMIT,
  NEW_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS,
  NEW_ORDER_TABLE_ACTIONS_OPTIONS,
  ORDER_STATUS,
  ORDER_TYPE,
  ROUTES,
  SORT,
  SORTING,
  USER_ROLES,
} from '../../../../../common/constants';
import { formatDate, hasNonEmptyValueObj } from '../../../../../common/utils';
import CommonPreview from '../../../../../components/CommonPreview';
import CommonPreviewTable from '../../../../../components/CommonPreviewTable';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import SelectComponent from '../../../../../components/SelectComponent';
import SortDropdown from '../../../../../components/SortDropdown';
import { PINCODES } from '../../../../../components/graphql/Queries';
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll';
import ContentMasterModal from '../../../../../modules/orders/modules/all/components/ContentMasterModal';
import NoteModal from '../../../../../modules/orders/modules/all/components/NoteModal';
import { USERS } from '../../../../raiseRequest/graphql/Queries';
import {
  CANCEL_ORDER_LIBRARY,
  RESET_ORDER_CHALLAN,
  RESET_ORDER_ITEM_STICKER,
  UPDATE_ORDER_STATUS_LIBRARY,
} from '../graphql/Mutations';
import {
  GET_ORDER_CHALLAN_DOWNLOAD_URL,
  GET_ORDER_STICKER_DOWNLOAD_URL,
  ORDERS,
  ORDERS_SELECT,
  ORDER_ITEMS,
} from '../graphql/Queries';
import AddCommentModal from './AddCommentModal';
import AllFilterOrderModal from './AllFilterOrderModal';
import AllOrderItemModal from './AllOrderItemModal';
import AllOrderList from './AllOrderList';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialNewDeliveryFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialNewDeliverySort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const sort = [
  {
    label: SORTING?.ORDER_ID_A_Z?.label,
    value: SORTING?.ORDER_ID_A_Z?.value,
    field: SORTING?.ORDER_ID_A_Z?.field,
    sort: SORTING?.ORDER_ID_A_Z?.sort,
  },
  {
    label: SORTING?.ORDER_ID_Z_A?.label,
    value: SORTING?.ORDER_ID_Z_A?.value,
    field: SORTING?.ORDER_ID_Z_A?.field,
    sort: SORTING?.ORDER_ID_Z_A?.sort,
  },
];

const AllDeliveryTable = ({ exportFilter, setExportFilter }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const {
    state: { isDesktop },
  } = useContext(AppContext);
  const queryParams = new URLSearchParams(location?.search);
  const orderId = queryParams?.get('orderId') || '';
  const status = queryParams?.get('status') || '';
  const [orderList, setOrderList] = useState([]);
  const [orderSearchTerm, setOrderSearchTerm] = useState('');
  const [isEmptyOrderList, setIsEmptyOrderList] = useState(false);
  const [order, setOrder] = useState({});
  const [orderItem, setOrderItem] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isOrderLoading, setIsOrderLoading] = useState(true);
  const [isNotePrompts, setIsNotePrompts] = useState(false);
  const [isAddCommentModalOpen, setIsAddCommentModalOpen] = useState(false);
  const [isAddCommentLoading, setIsAddCommentLoading] = useState(false);
  const [isOrderAction, setIsOrderAction] = useState(false);
  const [isFulfillLoading, setIsFulfillLoading] = useState(false);
  const [isFulfillPrompts, setIsFulfillPrompts] = useState(false);
  const [isReadyLoading, setIsReadyLoading] = useState(false);
  const [isReadyPrompts, setIsReadyPrompts] = useState(false);
  const [isChallanLoading, setIsChallanLoading] = useState({});
  const [isResetLoading, setIsResetLoading] = useState({});
  const [isStickerLoading, setIsStickerLoading] = useState({});
  const [isResetStickerLoading, setIsResetStickerLoading] = useState({});

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [nestedData, setNestedData] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);
  const isNewPath = location?.pathname?.includes(ROUTES?.NEW);
  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [toyPreviewImage, setToyPreviewImage] = useState();

  const [isAllFilterModalOpen, setIsAllFilterModalOpen] = useState(false);
  const [isFetchMoreOrderLoading, setIsFetchMoreOrderLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isAllOrderItemModalOpen, setIsAllOrderItemModalOpen] = useState(false);
  const [isAllSortPopoverOpen, setIsAllSortPopoverOpen] = useState(false);
  const [isContentMasterModalOpen, setIsContentMasterModalOpen] = useState(
    false,
  );

  const [orders] = useLazyQuery(ORDERS, {
    onCompleted: (response) => {
      if (isDesktop) {
        setOrderList([...response?.orders?.data]);
      } else {
        setOrderList([...orderList, ...response?.orders?.data]);
      }
      if (
        response?.orders?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyOrderList(true);
      } else {
        setIsEmptyOrderList(false);
      }
      if (response?.orders?.data?.length < LIMIT) {
        setIsActive(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.orders?.count,
      };
      setPaginationProp(pagination);
      setIsOrderLoading(false);
      setIsFetchMoreOrderLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [orderItems] = useLazyQuery(ORDER_ITEMS, {
    fetchPolicy: 'no-cache',
    onError() {},
  });

  const [getOrderChallanDownloadUrl] = useLazyQuery(
    GET_ORDER_CHALLAN_DOWNLOAD_URL,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [getOrderStickerDownloadUrl] = useLazyQuery(
    GET_ORDER_STICKER_DOWNLOAD_URL,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [users, { loading: isUsersLoading }] = useLazyQuery(USERS, {
    onCompleted: (response) => {
      if (response?.users?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.users?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.users?.data, (item) =>
        optionsCopy?.push({
          label: `${
            item?.firstName || item?.lastName
              ? `${item?.firstName || ''} ${item?.lastName || ''}`
              : '-'
          }`,
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [pincodes, { loading: isPincodesLoading }] = useLazyQuery(PINCODES, {
    onCompleted: (response) => {
      if (response?.pincodes?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.pincodes?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.pincodes?.data, (item) =>
        optionsCopy?.push({
          label: item?.pincode,
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [orderSelect, { loading: isOrderSelectLoading }] = useLazyQuery(
    ORDERS_SELECT,
    {
      onCompleted: (response) => {
        if (response?.orders?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.orders?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.orders?.data, (item) =>
          optionsCopy?.push({
            label: item?.orderNumber,
            key: item?.id,
          }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [cancelOrderLibrary] = useMutation(CANCEL_ORDER_LIBRARY, {
    onError: () => {},
  });

  const [updateOrderStatusLibrary] = useMutation(UPDATE_ORDER_STATUS_LIBRARY, {
    onError: () => {},
  });

  const [resetOrderChallan] = useMutation(RESET_ORDER_CHALLAN, {
    onError: () => {},
  });

  const [resetOrderItemSticker] = useMutation(RESET_ORDER_ITEM_STICKER, {
    onError: () => {},
  });

  useEffect(() => {
    setOrderSearchTerm(orderId || orderSearchTerm);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    setOrderList([]);
    let finalStatus;
    const { text, value } = find(map(ORDER_STATUS), { value: status }) || {};
    if (value) {
      const filterValue = { status: [value] };
      const filterTagValue = { status: [{ label: text, key: value }] };
      finalStatus = [value];
      setFinalWhereFilters(filterValue);
      setWhereFilter(filterValue);
      setFilterTags(filterTagValue);
      setFinalFilterTags(filterTagValue);
    } else if (finalWhereFilters?.status?.length > 0) {
      finalStatus = finalWhereFilters.status;
    }
    orders({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          search: orderId || orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
          orderIds: finalWhereFilters?.orderNumber,
          deliveryType: finalWhereFilters?.deliveryType,
          status: finalStatus,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: finalWhereFilters?.deliveredOn?.from?.$d,
            to: finalWhereFilters?.deliveredOn?.to?.$d,
          },
          pincodeIds: finalWhereFilters?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
  }, [isDesktop, status]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsOrderLoading(true);
    setSortedInfo(sorter);
    setNestedData({});
    setExpandedRowKeys([]);
    orders({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          skip,
          limit: pagination?.pageSize,
          orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
          orderIds: finalWhereFilters?.orderNumber,
          search: orderSearchTerm,
          deliveryType: finalWhereFilters?.deliveryType,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: finalWhereFilters?.deliveredOn?.from?.$d,
            to: finalWhereFilters?.deliveredOn?.to?.$d,
          },
          pincodeIds: finalWhereFilters?.pincodes,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setOrderSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    setOrderList([]);
    setNestedData({});
    setExpandedRowKeys([]);
    orders({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          limit: paginationProp?.pageSize || LIMIT,
          orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
          orderIds: finalWhereFilters?.orderNumber,
          search: trimValue,
          deliveryType: finalWhereFilters?.deliveryType,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: finalWhereFilters?.deliveredOn?.from?.$d,
            to: finalWhereFilters?.deliveredOn?.to?.$d,
          },
          pincodeIds: finalWhereFilters?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleAddCommentInOrder = async (values) => {
    setIsAddCommentLoading(true);
    const response = await cancelOrderLibrary({
      variables: {
        where: {
          id: order?.id,
        },
        data: {
          additionalComment: values?.comment?.trim(),
          reason: values?.reason,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsAddCommentModalOpen(false);
      setNestedData({});
      setExpandedRowKeys([]);
      setOrderList([]);
      form?.resetFields();
      orders({
        variables: {
          filter: {
            ...initialNewDeliveryFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
            orderIds: finalWhereFilters?.orderNumber,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            deliveredOn: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            pincodeIds: finalWhereFilters?.pincodes,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialNewDeliverySort,
        },
      });
    }
    setIsAddCommentLoading(false);
  };

  const handleAddCommentInOrderItem = async (values) => {
    setIsAddCommentLoading(true);
    setIsAllOrderItemModalOpen(false);
    const response = await updateOrderStatusLibrary({
      variables: {
        where: {
          id: order?.id,
        },
        data: {
          orderItemId: orderItem?.id,
          additionalComment: values?.comment?.trim(),
          reason: values?.reason,
          status: FULFILLMENT_STATUS?.UNFULFILLED?.value,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsAddCommentModalOpen(false);
      setNestedData({});
      setExpandedRowKeys([]);
      setOrderList([]);
      form?.resetFields();
      orders({
        variables: {
          filter: {
            ...initialNewDeliveryFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
            orderIds: finalWhereFilters?.orderNumber,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            deliveredOn: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            pincodeIds: finalWhereFilters?.pincodes,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialNewDeliverySort,
        },
      });
    }
    setIsAddCommentLoading(false);
  };

  const handleFulfillOrderItem = async () => {
    setIsAllOrderItemModalOpen(false);
    setIsFulfillLoading(true);
    const response = await updateOrderStatusLibrary({
      variables: {
        where: {
          id: order?.id,
        },
        data: {
          orderItemId: orderItem?.id,
          status: FULFILLMENT_STATUS?.FULFILLED?.value,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsFulfillPrompts(false);
      setNestedData({});
      setOrderList([]);
      setExpandedRowKeys([]);
      orders({
        variables: {
          filter: {
            ...initialNewDeliveryFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
            orderIds: finalWhereFilters?.orderNumber,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            deliveredOn: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            pincodeIds: finalWhereFilters?.pincodes,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialNewDeliverySort,
        },
      });
    }
    setIsFulfillLoading(false);
  };

  const handleReadyOrder = async () => {
    setIsAllOrderItemModalOpen(false);
    setIsReadyLoading(true);
    const response = await updateOrderStatusLibrary({
      variables: {
        where: {
          id: order?.id,
        },
        data: {
          status: ORDER_STATUS?.CONFIRMED?.value,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsReadyPrompts(false);
      setNestedData({});
      setOrderList([]);
      setExpandedRowKeys([]);
      orders({
        variables: {
          filter: {
            ...initialNewDeliveryFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
            orderIds: finalWhereFilters?.orderNumber,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            deliveredOn: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            pincodeIds: finalWhereFilters?.pincodes,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialNewDeliverySort,
        },
      });
    }
    setIsReadyLoading(false);
  };

  const handleFilter = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    setIsAllFilterModalOpen(false);
    setOrderList([]);
    setIsActive(true);
    const filters = {
      status: values?.status,
      createdAt: values?.createdAt?.[1]
        ? { from: values?.createdAt?.[0], to: values?.createdAt?.[1] }
        : null,
      deliveredOn: values?.deliveredOn?.[1]
        ? { from: values?.deliveredOn?.[0], to: values?.deliveredOn?.[1] }
        : null,
      pincodes: values?.pincodes,
      deliveryType: values?.deliveryType,
    };
    setWhereFilter(filters);
    setFinalWhereFilters(filters);
    orders({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          limit: paginationProp?.pageSize || LIMIT,
          orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
          search: orderSearchTerm,
          deliveryType: values?.deliveryType,
          status: values?.status?.length > 0 ? values?.status : undefined,
          dateRange: {
            from: values?.createdAt?.[0],
            to: values?.createdAt?.[1],
          },
          deliveredOn: {
            from: values?.deliveredOn?.[0],
            to: values?.deliveredOn?.[1],
          },
          pincodeIds: values?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
  };

  const handleOrderScroll = () => {
    if (!isFetchMoreOrderLoading && !isOrderLoading) {
      const currentLength = orderList?.length;
      setIsFetchMoreOrderLoading(true);
      orders({
        variables: {
          filter: {
            ...initialNewDeliveryFilter,
            skip: currentLength,
            limit: paginationProp?.pageSize || LIMIT,
            orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
            orderIds: finalWhereFilters?.orderNumber,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            deliveredOn: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            pincodeIds: finalWhereFilters?.pincodes,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialNewDeliverySort,
        },
      });
    }
  };

  const handleSort = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    setIsAllSortPopoverOpen(false);
    setIsActive(true);
    setOrderList([]);
    const filteredSort = sort?.filter((item) => item?.value === values?.sort);
    if (filteredSort?.[0]?.field) {
      setSortedInfo({
        ...sortedInfo,
        field: filteredSort?.[0]?.field,
        columnKey: filteredSort?.[0]?.field,
        order: filteredSort?.[0]?.sort === SORT?.ASC ? 'ascend' : 'descend',
        column: filteredSort?.[0]?.field,
      });
    }
    orders({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          limit: paginationProp?.pageSize || LIMIT,
          orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
          orderIds: finalWhereFilters?.orderNumber,
          search: orderSearchTerm,
          deliveryType: finalWhereFilters?.deliveryType,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: finalWhereFilters?.deliveredOn?.from?.$d,
            to: finalWhereFilters?.deliveredOn?.to?.$d,
          },
          pincodeIds: finalWhereFilters?.pincodes,
        },
        sort: filteredSort?.[0]
          ? {
              field: filteredSort?.[0]?.field,
              order: filteredSort?.[0]?.sort,
            }
          : initialNewDeliverySort,
      },
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-use-before-define
  const infiniteScrollRef = useInfiniteScroll(handleOrderScroll, isActive);

  const handleChallanDownload = async (id, windowReference) => {
    setIsChallanLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: true,
    }));
    const response = await getOrderChallanDownloadUrl({
      variables: {
        where: {
          id,
        },
      },
    });
    if (response?.data) {
      // eslint-disable-next-line no-param-reassign
      windowReference.location =
        response?.data?.getOrderChallanDownloadUrl?.challanUrl;
    }
    setIsChallanLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: false,
    }));
  };

  const handleChallanReset = async (id) => {
    setIsResetLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: true,
    }));
    await resetOrderChallan({
      variables: {
        where: {
          id,
        },
      },
    });
    setIsResetLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: false,
    }));
  };

  const handleStickerDownload = async (id, windowReference) => {
    setIsStickerLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: true,
    }));
    const response = await getOrderStickerDownloadUrl({
      variables: {
        where: {
          id,
        },
      },
    });
    if (response?.data) {
      // eslint-disable-next-line no-param-reassign
      windowReference.location =
        response?.data?.getOrderStickerDownloadUrl?.stickerUrl;
    }
    setIsStickerLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: false,
    }));
  };

  const handleStickerReset = async (id) => {
    setIsResetStickerLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: true,
    }));
    await resetOrderItemSticker({
      variables: {
        where: {
          id,
        },
      },
    });
    setIsResetStickerLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: false,
    }));
  };

  const getFilterData = (confirm) => {
    setIsOrderLoading(true);
    setNestedData({});
    setExpandedRowKeys([]);
    orders({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          search: orderSearchTerm,
          orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
          orderIds: whereFilter?.orderNumber,
          deliveryType: whereFilter?.deliveryType,
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: whereFilter?.deliveredOn?.from?.$d,
            to: whereFilter?.deliveredOn?.to?.$d,
          },
          pincodeIds: whereFilter?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'deliveryType') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsOrderLoading(true);
    setNestedData({});
    setExpandedRowKeys([]);
    orders({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          search: orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
          orderIds: whereFiltersCopy?.orderNumber,
          deliveryType: whereFiltersCopy?.deliveryType,
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: whereFiltersCopy?.deliveredOn?.from?.$d,
            to: whereFiltersCopy?.deliveredOn?.to?.$d,
          },
          pincodeIds: whereFiltersCopy?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      const optionsCopy = [];
      const orderStatus = isNewPath
        ? map(
            filter(
              ORDER_STATUS,
              (item) =>
                item?.value === ORDER_STATUS?.PLACED?.value ||
                item?.value === ORDER_STATUS?.CONFIRMED?.value,
            ),
            (item) => item,
          )
        : ORDER_STATUS;

      switch (filterIndex) {
        case 'deliveryPartnerName':
          users({
            variables: {
              filter: {
                ...initialTableFilter,
                roles: [USER_ROLES?.DELIVERY?.key],
              },
              sort: initialTableSort,
            },
          });
          break;

        case 'users':
          users({
            variables: {
              filter: {
                ...initialTableFilter,
                roles: [USER_ROLES?.USER?.key],
              },
              sort: initialTableSort,
            },
          });
          break;

        case 'pincodes':
          pincodes({
            variables: {
              filter: {
                ...initialTableFilter,
              },
              sort: {
                field: 'pincode',
                order: 'ASC',
              },
            },
          });
          break;

        case 'deliveryType':
          forEach(DELIVERY_TYPE_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'orderNumber':
          orderSelect({
            variables: {
              filter: {
                ...initialTableFilter,
                orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
              },
              sort: initialTableSort,
            },
          });
          break;

        case 'status':
          forEach(orderStatus, (item) => {
            optionsCopy?.push({ label: item?.text, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (dataIndex === 'status') {
      return;
    }
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (
      !isUsersLoading &&
      !isPincodesLoading &&
      !isOrderSelectLoading &&
      !filterIsEnd
    ) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'deliveryPartnerName':
              users({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                    roles: [USER_ROLES?.DELIVERY?.key],
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'users':
              users({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                    roles: [USER_ROLES?.USER?.key],
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'pincodes':
              pincodes({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: {
                    field: 'pincode',
                    order: 'ASC',
                  },
                },
              });
              break;

            case 'orderNumber':
              orderSelect({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                    orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'deliveryPartnerName':
        users({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
              roles: [USER_ROLES?.DELIVERY?.key],
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'users':
        users({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
              roles: [USER_ROLES?.USER?.key],
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'pincodes':
        pincodes({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: {
              field: 'pincode',
              order: 'ASC',
            },
          },
        });
        break;

      case 'orderNumber':
        orderSelect({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
              orderType: isNewPath ? ORDER_TYPE?.NEW : ORDER_TYPE?.ALL,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt' || dataIndex === 'deliveredOn') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'deliveryType') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={DELIVERY_TYPE_OPTIONS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const handleExpand = async (expanded, record) => {
    setIsLoading({
      [record?.id]: true,
    });
    setIsDisabled(true);
    if (!nestedData?.[record?.id]) {
      const res = await orderItems({
        variables: {
          where: {
            id: record?.id,
          },
        },
      });
      if (res?.data) {
        setNestedData((state) => ({
          ...state,
          [record?.id]: res?.data,
        }));
      }
      setIsDisabled(false);
    }
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record?.id]);
    } else {
      setExpandedRowKeys(expandedRowKeys?.filter((key) => key !== record?.id));
    }
    setIsLoading({
      [record?.id]: false,
    });
    setIsDisabled(false);
  };

  const handleOrderChange = (value, record) => {
    setOrder(record);
    setIsOrderAction(true);
    if (value === FULFILLMENT_STATUS?.FULFILLED?.value) {
      setIsReadyPrompts(true);
    } else {
      setIsAddCommentModalOpen(true);
    }
  };

  const handleOrderItemChange = (value, record, orderDetails) => {
    setOrderItem(record);
    setOrder(orderDetails);
    setIsOrderAction(false);
    if (value === FULFILLMENT_STATUS?.FULFILLED?.value) {
      setIsFulfillPrompts(true);
    } else {
      setIsAddCommentModalOpen(true);
    }
  };

  const columns = [
    {
      title: 'ORDER ID',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      sorter: true,
      width: 150,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (_, record) => record?.orderNumber,
    },
    {
      title: 'QUANTITY',
      dataIndex: 'quantity',
      key: 'quantity',
      ellipsis: true,
      align: 'left',
      width: 100,
      className: 'max-width-column',
      render: (_, record) => record?.orderItemCount || '-',
    },
    {
      title: 'DELIVERY TYPE',
      dataIndex: 'deliveryType',
      key: 'deliveryType',
      ellipsis: true,
      align: 'left',
      width: 150,
      className: 'max-width-column',
      render: (_, record) =>
        DELIVERY_TYPE?.[record?.deliveryType]?.label || '-',
      ...filterPopup('deliveryType'),
    },
    {
      title: 'USER ID',
      dataIndex: 'userId',
      key: 'userId',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) => record?.user?.userNumber || '-',
    },
    {
      title: 'DELIVERY PARTNER NAME',
      dataIndex: 'deliveryPartnerName',
      key: 'deliveryPartnerName',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) => (
        <>
          {record?.deliveryPartner?.firstName ||
          record?.deliveryPartner?.lastName ? (
            <div className="table-data-with-id">{`${
              record?.deliveryPartner?.firstName
                ? record?.deliveryPartner?.firstName
                : ''
            } ${
              record?.deliveryPartner?.lastName
                ? record?.deliveryPartner?.lastName
                : ''
            }`}</div>
          ) : (
            '-'
          )}
          {record?.deliveryPartner?.userNumber ? (
            <div className="font-size-12">
              ({record?.deliveryPartner?.userNumber})
            </div>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'PINCODE',
      dataIndex: 'pincode',
      key: 'pincode',
      ellipsis: true,
      align: 'left',
      width: 150,
      className: 'max-width-column',
      render: (_, record) => record?.pincode?.pincode || '-',
      ...filterPopup('pincodes'),
    },
    {
      title: 'PLACED ON',
      dataIndex: 'placedOn',
      key: 'placedOn',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.WITH_TIME),
      ...filterPopup('createdAt'),
    },
    {
      title: isNewPath ? 'ESTIMATED SHIPPING DATE' : 'DELIVERED ON',
      dataIndex: 'deliveredOn',
      key: 'deliveredOn',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.deliveredOn, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('deliveredOn'),
    },
    {
      title: 'SHIPPED ATTACHMENTS',
      dataIndex: 'shippedAttachments',
      key: 'shippedAttachments',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const shippedAttachments = record?.deliveryAttachments?.filter(
          (attachment) =>
            attachment?.deliveryAttachmentsType ===
            ORDER_STATUS?.SHIPPED?.value,
        );

        const hasAttachments = shippedAttachments?.length > 0;
        const additionalCount = shippedAttachments?.length - 1;

        return (
          <span>
            {hasAttachments ? (
              <Image
                preview={{ visible: false }}
                width={20}
                height={20}
                src={shippedAttachments?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(shippedAttachments);
                }}
                className="pointer"
              />
            ) : (
              <span>No Images</span>
            )}
            {additionalCount > 0 && ` + ${additionalCount}`}
          </span>
        );
      },
    },
    {
      title: 'DELIVERED ATTACHMENTS',
      dataIndex: 'deliveredAttachments',
      key: 'deliveredAttachments',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const deliveredAttachments = record?.deliveryAttachments?.filter(
          (attachment) =>
            [ORDER_STATUS?.DELIVERED?.value, null]?.includes(
              attachment?.deliveryAttachmentsType,
            ),
        );

        const hasAttachments = deliveredAttachments?.length > 0;
        const additionalCount = deliveredAttachments?.length - 1;

        return (
          <span>
            {hasAttachments ? (
              <Image
                preview={{ visible: false }}
                width={20}
                height={20}
                src={deliveredAttachments?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(deliveredAttachments);
                }}
                className="pointer"
              />
            ) : (
              <span>No Images</span>
            )}
            {additionalCount > 0 && ` + ${additionalCount}`}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={ORDER_STATUS?.[record?.status]?.color}
          text={ORDER_STATUS?.[record?.status]?.text}
        />
      ),
      ...filterPopup('status', false),
    },
    {
      title: 'NOTE',
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <>
          {record?.cancellationData?.type ===
          CANCELLATION_TYPE?.LIBRARY?.value ? (
            <div className="action-button">
              <Tooltip title="View comment" placement="top">
                <CommentIcon
                  className="mr-16 pointer svg-icon"
                  onClick={() => {
                    setIsNotePrompts(true);
                    setOrder(record);
                    setIsOrderAction(true);
                  }}
                />
              </Tooltip>
            </div>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: 250,
      render: (_, record) => {
        let selectedValue = null;
        if (
          record?.status === ORDER_STATUS?.CANCELLED?.value ||
          record?.status === ORDER_STATUS?.CONFIRMED?.value
        ) {
          if (record?.status === ORDER_STATUS?.CANCELLED?.value) {
            selectedValue = FULFILLMENT_STATUS?.UNFULFILLED?.value;
          } else {
            selectedValue = FULFILLMENT_STATUS?.FULFILLED?.value;
          }
        } else {
          selectedValue = null;
        }
        return (
          <>
            {isNewPath && (
              <SelectComponent
                size="small"
                className="full-width"
                placeholder="Select Action"
                showAction={['click']}
                value={selectedValue}
                showSearch={false}
                disabled={!!selectedValue}
                options={NEW_ORDER_TABLE_ACTIONS_OPTIONS}
                onSelect={(value) => handleOrderChange(value, record)}
                allowClear={false}
              />
            )}
            {!isNewPath && (
              <SelectComponent
                size="small"
                className="full-width"
                placeholder="Select Action"
                showAction={['click']}
                value={
                  record?.status === ORDER_STATUS?.CANCELLED?.value
                    ? record?.status
                    : null
                }
                showSearch={false}
                options={LIBRARY_ALL_DELIVERY_TABLE_ACTIONS_OPTIONS}
                onSelect={(value) => handleOrderChange(value, record)}
                disabled={
                  record?.status === ORDER_STATUS?.CANCELLED?.value ||
                  record?.status === ORDER_STATUS?.DELIVERED?.value ||
                  record?.status === ORDER_STATUS?.SHIPPED?.value
                }
                allowClear={false}
              />
            )}
          </>
        );
      },
    },
    {
      title: 'DOWNLOAD PDF',
      dataIndex: 'downloadPDF',
      key: 'downloadPDF',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <>
          <div className="action-button">
            <div className="mr-16">
              <Button
                type="primary"
                size="small"
                className="table-action-primary-btn"
                onClick={() => {
                  // eslint-disable-next-line no-undef
                  const windowReference = window.open();
                  handleChallanDownload(record?.id, windowReference);
                }}
                loading={isChallanLoading?.[record?.id]}
                disabled={
                  ![
                    ORDER_STATUS?.READY_TO_SHIP?.value,
                    ORDER_STATUS?.SHIPPED?.value,
                    ORDER_STATUS?.CONFIRMED?.value,
                    ORDER_STATUS?.DELIVERED?.value,
                  ]?.includes(record?.status)
                }
              >
                Download Challan
              </Button>
            </div>
            <Button
              type="primary"
              size="small"
              className="table-action-primary-btn"
              onClick={() => handleChallanReset(record?.id)}
              loading={isResetLoading?.[record?.id]}
              disabled={
                ![
                  ORDER_STATUS?.READY_TO_SHIP?.value,
                  ORDER_STATUS?.CONFIRMED?.value,
                ]?.includes(record?.status)
              }
            >
              Reset Challan
            </Button>
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (
      ['downloadPDF', 'shippedAttachments', 'deliveredAttachments']?.includes(
        item?.dataIndex,
      ) &&
      isNewPath
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const expandedRowRender = (data) => {
    const orderItemList = nestedData?.[data?.id]?.orderItems?.data;
    const nestedColumns = [
      {
        title: 'PRODUCT NAME',
        dataIndex: 'productName',
        key: 'productName',
        ellipsis: true,
        align: 'left',
        width: 250,
        className: 'max-width-column',
        render: (_, record) => (
          <>
            <div className="table-data-with-id">{record?.toy?.name || '-'}</div>
            {record?.toy?.toyNumber && (
              <div className="font-size-12">({record?.toy?.toyNumber})</div>
            )}
          </>
        ),
      },
      {
        title: 'IMAGES',
        dataIndex: 'images',
        key: 'images',
        ellipsis: true,
        width: 100,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => {
          const images = record?.toy?.images;
          return (
            <span>
              {images?.length > 0 ? (
                <>
                  <Image
                    preview={{
                      visible: false,
                    }}
                    width={20}
                    height={20}
                    src={images?.[0]?.url}
                    onClick={(e) => {
                      e?.stopPropagation();
                      setVisible(true);
                      setToyImages(images);
                    }}
                    className="pointer"
                  />
                </>
              ) : (
                <span>No Images</span>
              )}
              {images?.length > 1 ? ` + ${images?.length - 1}` : ''}
            </span>
          );
        },
      },
      {
        title: 'BRAND',
        dataIndex: 'brand',
        key: 'brand',
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) => record?.toy?.brand?.name || '-',
      },
      {
        title: 'Rack',
        dataIndex: 'rack',
        key: 'rack',
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) => record?.rack?.name || '-',
      },
      {
        title: 'PACKAGING MATERIAL',
        dataIndex: 'packagingMaterial',
        key: 'packagingMaterial',
        ellipsis: true,
        width: 250,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => (
          <>
            {record?.toy?.bag ? (
              <div>
                <span className="mr-8">
                  {record?.toy?.bag?.name} ({record?.toy?.bag?.size})
                </span>
                <Image
                  preview={{ visible: false }}
                  width={22}
                  src={record?.toy?.bag?.image}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setPreviewVisible(true);
                    setToyPreviewImage(record?.toy?.bag?.image);
                  }}
                  className="pointer"
                />
              </div>
            ) : (
              '-'
            )}
          </>
        ),
      },
      {
        title: 'AGE GROUPS',
        dataIndex: 'ageGroups',
        key: 'ageGroups',
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) =>
          record?.toy?.ageGroups?.map((ageGroup) =>
            ageGroup?.minAge > 18 ? (
              <Tag key={ageGroup?.id}>
                {Math.floor(ageGroup?.minAge / 12)} -{' '}
                {Math.floor(ageGroup?.maxAge / 12)} Years
              </Tag>
            ) : (
              <Tag key={ageGroup?.id}>
                {ageGroup?.minAge} - {ageGroup?.maxAge} Months
              </Tag>
            ),
          ),
      },
      {
        title: 'FULFILLMENT STATUS',
        dataIndex: 'fulfillmentStatus',
        key: 'fulfillmentStatus',
        ellipsis: true,
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) =>
          record?.status === FULFILLMENT_STATUS?.FULFILLED?.value ||
          record?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
          record?.status === FULFILLMENT_STATUS?.CANCELLED?.value ? (
            <Badge
              color={FULFILLMENT_STATUS?.[record?.status]?.color}
              text={FULFILLMENT_STATUS?.[record?.status]?.text}
            />
          ) : (
            '-'
          ),
      },
      {
        title: 'EXPECTED RETURN',
        dataIndex: 'returnDate',
        key: 'returnDate',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: 'max-width-column',
        render: (_, record) =>
          formatDate(record?.returnDate, DATE_FORMATS?.REGULAR) || '-',
      },
      {
        title: 'NOTE',
        dataIndex: 'note',
        key: 'note',
        ellipsis: true,
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) => (
          <div className="action-button">
            {record?.cancellationData?.type ===
            CANCELLATION_TYPE?.LIBRARY?.value ? (
              <Tooltip title="View comment" placement="top" zIndex={4}>
                <CommentIcon
                  className="mr-24 pointer svg-icon"
                  onClick={() => {
                    setIsNotePrompts(true);
                    setOrderItem(record);
                    setIsOrderAction(false);
                  }}
                />
              </Tooltip>
            ) : (
              <span className="mr-24">-</span>
            )}
          </div>
        ),
      },
      {
        title: 'ACTIONS',
        dataIndex: 'actions',
        key: 'actions',
        width: 250,
        render: (_, record) => (
          <SelectComponent
            size="small"
            className="full-width"
            placeholder="Select Action"
            showSearch={false}
            value={
              record?.status === FULFILLMENT_STATUS?.FULFILLED?.value ||
              record?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value
                ? record?.status
                : null
            }
            disabled={
              record?.status === FULFILLMENT_STATUS?.FULFILLED?.value ||
              record?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
              record?.status === FULFILLMENT_STATUS?.CANCELLED?.value
            }
            options={NEW_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS}
            onChange={(value) => handleOrderItemChange(value, record, data)}
            allowClear={false}
          />
        ),
      },
      {
        title: 'ACTIONS',
        dataIndex: 'allActions',
        key: 'allActions',
        ellipsis: true,
        width: 400,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => (
          <>
            <div className="action-button">
              <div className="mr-16">
                <Button
                  type="primary"
                  size="small"
                  className="table-action-primary-btn"
                  onClick={() => {
                    // eslint-disable-next-line no-undef
                    const windowReference = window.open();
                    handleStickerDownload(record?.id, windowReference);
                  }}
                  loading={isStickerLoading?.[record?.id]}
                  disabled={
                    record?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
                    record?.status === FULFILLMENT_STATUS?.CANCELLED?.value ||
                    ![
                      ORDER_STATUS?.CONFIRMED?.value,
                      ORDER_STATUS?.READY_TO_SHIP?.value,
                    ]?.includes(data?.status)
                  }
                >
                  Download Sticker
                </Button>
              </div>
              <div className="mr-16">
                <Button
                  type="primary"
                  size="small"
                  className="table-action-primary-btn"
                  onClick={() => handleStickerReset(record?.id)}
                  loading={isResetStickerLoading?.[record?.id]}
                  disabled={
                    record?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
                    record?.status === FULFILLMENT_STATUS?.CANCELLED?.value ||
                    ![
                      ORDER_STATUS?.CONFIRMED?.value,
                      ORDER_STATUS?.READY_TO_SHIP?.value,
                    ]?.includes(data?.status)
                  }
                >
                  Reset Sticker
                </Button>
              </div>
              <Button
                type="primary"
                size="small"
                className="table-action-primary-btn"
                onClick={() => {
                  setOrderItem(record);
                  setIsOrderAction(false);
                  setIsContentMasterModalOpen(true);
                }}
              >
                View Content Logs
              </Button>
            </div>
          </>
        ),
      },
    ]?.filter((item) => {
      if (
        (!isNewPath && item?.dataIndex === 'actions') ||
        (isNewPath && item?.dataIndex === 'allActions')
      ) {
        return item?.hidden;
      }
      return !item?.hidden;
    });

    return (
      <CommonTable
        loading={isLoading[data?.id]}
        columns={nestedColumns}
        dataSource={orderItemList || []}
        pagination={false}
        rowKey={(record) => record?.id}
        className="nested-table"
      />
    );
  };

  const locale = {
    emptyText: isEmptyOrderList ? '' : <span />,
  };

  const expandIcon = ({ expanded, onExpand, record }) =>
    expanded ? (
      <button
        type="button"
        className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded disabled"
        aria-label="Expand row"
        aria-expanded="false"
        onClick={(e) => onExpand(record, e)}
        disabled={isDisabled}
      />
    ) : (
      <button
        type="button"
        className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
        aria-label="Collapse row"
        aria-expanded="true"
        onClick={(e) => onExpand(record, e)}
        disabled={isDisabled}
      />
    );

  const handleCancel = () => {
    setIsContentMasterModalOpen(false);
    setOrderItem({});
  };

  return (
    <>
      <ContentMasterModal
        isModalOpen={isContentMasterModalOpen}
        contentHistory={orderItem?.contentHistory}
        handleCancel={handleCancel}
      />
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isFulfillPrompts}
        onOk={handleFulfillOrderItem}
        onCancel={() => setIsFulfillPrompts(false)}
        okButtonProps={{ loading: isFulfillLoading }}
      >
        Are you sure you want to
        <strong> Mark as fulfill </strong>
        this order item?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isReadyPrompts}
        onOk={handleReadyOrder}
        onCancel={() => setIsReadyPrompts(false)}
        okButtonProps={{ loading: isReadyLoading }}
      >
        Are you sure you want to
        <strong> Mark as ready </strong>
        this order?
      </Modal>
      <AddCommentModal
        form={form}
        isOrderAction={isOrderAction}
        isModalOpen={isAddCommentModalOpen}
        setIsModalOpen={setIsAddCommentModalOpen}
        isBtnLoading={isAddCommentLoading}
        onFinish={
          isOrderAction ? handleAddCommentInOrder : handleAddCommentInOrderItem
        }
      />
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <CommonPreview
        visible={previewVisible}
        setVisible={setPreviewVisible}
        image={toyPreviewImage}
        setImage={setToyPreviewImage}
      />
      <NoteModal
        note={
          isOrderAction ? order?.cancellationData : orderItem?.cancellationData
        }
        isModalOpen={isNotePrompts}
        setIsModalOpen={setIsNotePrompts}
        isOrderAction={isOrderAction}
        adminNote={
          isOrderAction ? order?.comment?.comment : orderItem?.comment?.comment
        }
      />
      <div className="d-flex justify-end align-center mb-16">
        <SearchComponent
          className={isDesktop ? 'search-box' : ''}
          query={orderSearchTerm}
          setQuery={setOrderSearchTerm}
          getData={handleSearch}
        />
        {!isDesktop && (
          <>
            <Badge dot={hasNonEmptyValueObj(finalWhereFilters)} size="small">
              <FilterIcon
                className="mobile-svg-icon ml-16"
                onClick={() => setIsAllFilterModalOpen(true)}
              />
            </Badge>
            <div className="ml-16 d-flex align-center">
              <SortDropdown
                isOpen={isAllSortPopoverOpen}
                setIsOpen={setIsAllSortPopoverOpen}
                options={map(sort, (item) => item)}
                sortedInfo={sortedInfo}
                setSortedInfo={setSortedInfo}
                onFinish={handleSort}
              />
            </div>
          </>
        )}
      </div>
      {isDesktop ? (
        <CommonTable
          locale={locale}
          columns={columns}
          data={orderList || []}
          loading={isOrderLoading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(record) => record?.id}
          expandedRowKeys={expandedRowKeys}
          onExpand={(_, record) => setOrder(record)}
          expandable={{
            onExpand: (expanded, record) => handleExpand(expanded, record),
            expandedRowRender: (record) => expandedRowRender(record),
            expandIcon,
          }}
        />
      ) : (
        <div
          className={`${
            isNewPath
              ? 'mobile-container-search-action'
              : 'mobile-container-tab'
          } no-scroll-bar`}
        >
          <AllFilterOrderModal
            onFinish={handleFilter}
            isModalOpen={isAllFilterModalOpen}
            setIsModalOpen={setIsAllFilterModalOpen}
            finalWhereFilters={finalWhereFilters}
            setFinalWhereFilters={setFinalWhereFilters}
            isNewPath={isNewPath}
          />
          <AllOrderItemModal
            order={order}
            isNewPath={isNewPath}
            setOrder={setOrder}
            isModalOpen={isAllOrderItemModalOpen}
            setIsModalOpen={setIsAllOrderItemModalOpen}
            handleExpand={handleExpand}
            nestedData={nestedData}
            isLoading={isLoading}
            setIsNotePrompts={setIsNotePrompts}
            setOrderItem={setOrderItem}
            setIsOrderAction={setIsOrderAction}
            handleOrderItemChange={handleOrderItemChange}
            handleStickerDownload={handleStickerDownload}
            isStickerLoading={isStickerLoading}
            handleStickerReset={handleStickerReset}
            isResetStickerLoading={isResetStickerLoading}
            setIsContentMasterModalOpen={setIsContentMasterModalOpen}
            setVisible={setVisible}
            setToyImages={setToyImages}
          />
          <AllOrderList
            orderList={orderList}
            infiniteScrollRef={infiniteScrollRef}
            isFetchMoreOrderLoading={isFetchMoreOrderLoading}
            isEmptyOrderList={isEmptyOrderList}
            isOrderLoading={isOrderLoading}
            setOrder={setOrder}
            isNewPath={isNewPath}
            setIsNotePrompts={setIsNotePrompts}
            setIsOrderAction={setIsOrderAction}
            handleOrderChange={handleOrderChange}
            setIsAllOrderItemModalOpen={setIsAllOrderItemModalOpen}
            handleChallanDownload={handleChallanDownload}
            isChallanLoading={isChallanLoading}
            handleChallanReset={handleChallanReset}
            isResetLoading={isResetLoading}
            setVisible={setVisible}
            setToyImages={setToyImages}
          />
        </div>
      )}
    </>
  );
};

export default AllDeliveryTable;
