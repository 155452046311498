import { gql } from '@apollo/client';

export const GET_SIGNED_BULK_UPDATE_URL = gql`
  query GetBulkUpdateSignedPutUrl($data: GetBulkUpdateSignedPutUrlInput) {
    getBulkUpdateSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const LIBRARY_INVENTORIES = gql`
  query libraryInventories(
    $filter: LibraryInventoryFilter
    $sort: LibraryInventorySort
  ) {
    libraryInventories(filter: $filter, sort: $sort) {
      count
      data {
        id
        createdAt
        isActive
        rentedOut
        totalStock
        damagedStock
        availableStock
        rack {
          name
          id
        }
        toy {
          id
          name
          toyNumber
          isArchived
          images {
            key
            url
          }
          brand {
            id
            name
          }
          categories {
            id
            name
          }
          facilitates {
            id
            name
          }
          tags {
            id
            name
          }
          ageGroups {
            id
            maxAge
            minAge
          }
          material {
            id
            name
          }
          bag {
            id
            name
            size
            image
          }
        }
        updatedAt
      }
    }
  }
`;

export const LIBRARY_INVENTORY_BULK_UPDATE_LOGS = gql`
  query libraryInventoryBulkUpdateLogs(
    $filter: LibraryInventoryBulkUpdateLogsFilterInput
    $sort: LibraryBulkUpdatesSort
  ) {
    libraryInventoryBulkUpdateLogs(filter: $filter, sort: $sort) {
      data {
        id
        updatedAt
        oldQuantity
        updatedQuantity
        reason
        libraryInventory {
          id
          totalStock
          toy {
            id
            name
            toyNumber
            categories {
              id
              name
            }
            images {
              url
              key
            }
          }
          library {
            id
            name
          }
        }
      }
      count
    }
  }
`;

export const BRANDS = gql`
  query Brands($filter: BrandFilter, $sort: BrandSort) {
    brands(filter: $filter, sort: $sort) {
      data {
        id
        name
        createdAt
        order
        updatedAt
      }
    }
  }
`;

export const MATERIALS = gql`
  query Materials($filter: MaterialFilter, $sort: MaterialSort) {
    materials(filter: $filter, sort: $sort) {
      data {
        id
        name
        order
        createdAt
        updatedAt
      }
    }
  }
`;

export const FACILITATES = gql`
  query Facilitates($filter: FacilitateFilter, $sort: FacilitateSort) {
    facilitates(filter: $filter, sort: $sort) {
      data {
        name
        id
      }
    }
  }
`;

export const TAGS = gql`
  query Tags($filter: TagFilter, $sort: TagSort) {
    tags(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
      }
    }
  }
`;

export const GENRES = gql`
  query Genres($filter: GenreFilter, $sort: GenreSort) {
    tags(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
      }
    }
  }
`;

export const TOY = gql`
  query toy($where: ToyUniqueInput!) {
    toy(where: $where) {
      name
      imageUuid
      createdAt
      isArchived
      price
      url
      toyNumber
      material {
        name
        id
      }
      images {
        url
        size
        order
        key
        contentType
      }
      id
      description
      shortDescription
      eanNumber
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      facilitates {
        id
        name
      }
      ageGroups {
        maxAge
        minAge
        id
      }
      brand {
        name
        id
      }
      bag {
        id
        name
        size
        image
      }
      videoUrl
      includedContents {
        id
        quantity
        value
        key
      }
      excludedContents {
        id
        quantity
        key
        value
      }
    }
  }
`;

export const RACKS = gql`
  query Racks($filter: RackFilter, $sort: RackSort) {
    racks(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
      }
    }
  }
`;
