import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, Image, Row, Tag, Upload } from 'antd';
import React, { useState } from 'react';
import { getBase64 } from '../../../common/utils';
import CommonPreview from '../../../components/CommonPreview';

const { Item } = Form;

const ToyDetails = ({
  form,
  isView,
  toyImages,
  toyVideo,
  toyDetail,
  featuredImage,
}) => {
  const [visible, setVisible] = useState(false);
  const [toyPreviewImage, setToyPreviewImage] = useState();

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setToyPreviewImage(fileObj?.url || preview);
  };
  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={toyPreviewImage}
        setImage={setToyPreviewImage}
      />
      <Form
        layout="vertical"
        form={form}
        disabled={!!isView || toyDetail?.isArchived}
        className="common-view-form toy-details"
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
            <div className="mb-8 font-weight-bold">Product Id :</div>
            <div>{toyDetail?.toyNumber}</div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
            <div className="mb-8 font-weight-bold">Product Name :</div>
            <div>{toyDetail?.name}</div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
            <div className="mb-8 font-weight-bold">Product Price :</div>
            <div>{toyDetail?.price}</div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="mb-8 font-weight-bold">EAN Number :</div>
            <div>{toyDetail?.eanNumber || '-'}</div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div className="mb-8 font-weight-bold">Product URL :</div>
            <div>{toyDetail?.url || '-'}</div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div className="mb-8 font-weight-bold">Short Description :</div>
            <div>{toyDetail?.shortDescription || '-'}</div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div className="mb-8 font-weight-bold">Description :</div>
            <div>{toyDetail?.description || '-'}</div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="mb-8 font-weight-bold">Categories :</div>
            <div>
              {toyDetail?.categories?.map((category) => (
                <Tag key={category?.id}>{category?.name}</Tag>
              ))}
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="mb-8 font-weight-bold">Age Groups :</div>
            <div>
              {toyDetail?.ageGroups?.map((ageGroup) =>
                ageGroup?.minAge > 18 ? (
                  <Tag key={ageGroup?.id}>
                    {Math.floor(ageGroup?.minAge / 12)} -{' '}
                    {Math.floor(ageGroup?.maxAge / 12)} Years
                  </Tag>
                ) : (
                  <Tag key={ageGroup?.id}>
                    {ageGroup?.minAge} - {ageGroup?.maxAge} Months
                  </Tag>
                ),
              )}
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="mb-8 font-weight-bold">Brand :</div>
            <div>{toyDetail?.brand?.name}</div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="mb-8 font-weight-bold">Material :</div>
            <div>{toyDetail?.material?.name}</div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="mb-8 font-weight-bold">Packaging Material :</div>
            <div>
              <span className="mr-8">
                {toyDetail?.bag
                  ? `${toyDetail?.bag?.name} (${toyDetail?.bag?.size})`
                  : '-'}
              </span>
              <Image
                preview={{
                  visible: false,
                }}
                width={22}
                src={toyDetail?.bag?.image}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyPreviewImage(toyDetail?.bag?.image);
                }}
                className="pointer"
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="mb-8 font-weight-bold">Facilitates :</div>
            <div>
              {toyDetail?.facilitates?.length > 0
                ? toyDetail?.facilitates?.map((facilitate) => (
                    <Tag key={facilitate?.id}>{facilitate?.name}</Tag>
                  ))
                : '-'}
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="mb-8 font-weight-bold">Tags :</div>
            <div>
              {toyDetail?.tags?.length > 0
                ? toyDetail?.tags?.map((tag) => (
                    <Tag key={tag?.id}>{tag?.name}</Tag>
                  ))
                : '-'}
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="mb-8 font-weight-bold">Genres :</div>
            <div>
              {toyDetail?.genres?.length > 0
                ? toyDetail?.genres?.map((genre) => (
                    <Tag key={genre?.id}>{genre?.name}</Tag>
                  ))
                : '-'}
            </div>
          </Col>
          <Col xs={24} sm={12} className="content-master-form-list">
            <div className="mb-8 font-weight-bold">
              Included Content Master :
            </div>
            <div>
              <ul className="content-list-wrapper">
                {toyDetail?.includedContents?.length > 0
                  ? toyDetail?.includedContents?.map((content) => (
                      <li key={content?.id}>
                        {content?.value} - {content?.quantity}
                      </li>
                    ))
                  : '-'}
              </ul>
            </div>
          </Col>
          <Col xs={24} sm={12} className="content-master-form-list">
            <div className="mb-8 font-weight-bold">
              Excluded Content Master :
            </div>
            <div>
              <ul className="content-list-wrapper">
                {toyDetail?.excludedContents?.length > 0
                  ? toyDetail?.excludedContents?.map((content) => (
                      <li key={content?.id}>
                        {content?.value} - {content?.quantity}
                      </li>
                    ))
                  : '-'}
              </ul>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item label="Featured Image :" name="featuredImages">
              <Upload
                name="avatar"
                listType="picture-card"
                maxCount={1}
                fileList={featuredImage}
                beforeUpload={() => false}
                onPreview={handlePreview}
                className="avatar-uploader"
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: true,
                  showRemoveIcon: true,
                }}
              >
                {!isView && featuredImage?.length !== 1 && <PlusOutlined />}
              </Upload>
            </Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item label="Images :" name="images">
              <Upload
                name="avatar"
                listType="picture-card"
                maxCount={5}
                fileList={toyImages}
                beforeUpload={() => false}
                onPreview={handlePreview}
                className="avatar-uploader"
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: true,
                  showRemoveIcon: true,
                }}
              >
                {!isView && toyImages?.length !== 5 && <PlusOutlined />}
              </Upload>
            </Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item label="Video :" name="video">
              {toyVideo?.url ? (
                <div className="toy-video-container">
                  <video
                    width="100%"
                    height="100%"
                    controls
                    src={toyVideo?.url}
                    className="toy-video"
                  >
                    <track kind="captions" />
                  </video>
                </div>
              ) : (
                '-'
              )}
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ToyDetails;
